import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  CreatorContact,
  useCreateCreatorContactsMutation,
  useCreateOwnerContactsMutation,
  useDeleteCreatorContactsMutation,
  useDeleteOwnerContactsMutation,
} from "@/generated-types";
import { ALL_COUNTRIES } from "@/utils/options";

type AddressFormProps = {
  entity: "creator" | "owner";
  entityId: string | undefined;
  address:
    | Pick<
        CreatorContact,
        "country" | "addressLine1" | "addressLine2" | "city" | "stateProvince" | "postalCode"
      >
    | undefined;
  onClose?: () => void;
};

export function AddressForm({ entity, entityId, address, onClose }: AddressFormProps) {
  const [addCreatorContact, { loading: addingCreatorContact }] = useCreateCreatorContactsMutation({
    refetchQueries: ["GetCreatorContacts"],
  });

  const [addOwnerContact, { loading: addingOwnerContact }] = useCreateOwnerContactsMutation({
    refetchQueries: ["GetOwnerContacts"],
  });

  const [deleteCreatorContacts, { loading: deletingCreatorContacts }] =
    useDeleteCreatorContactsMutation();
  const [deleteOwnerContacts, { loading: deletingOwnerContacts }] =
    useDeleteOwnerContactsMutation();

  const handleAddressSave = async (newValue: z.infer<typeof FormSchema>) => {
    if (!entityId) return;
    const { country, addressLine1, addressLine2, city, stateProvince, postalCode } = newValue;

    const variables = {
      input: [
        {
          country,
          addressLine1,
          addressLine2,
          city,
          stateProvince,
          postalCode,
          [entity]: {
            connect: {
              where: {
                node: {
                  id: entityId,
                },
              },
            },
          },
        },
      ],
    };

    if (entity === "creator") {
      await deleteCreatorContacts({ variables: { creatorId: entityId } });
      await addCreatorContact({ variables });
    } else {
      await deleteOwnerContacts({ variables: { ownerId: entityId } });
      await addOwnerContact({ variables });
    }

    onClose && onClose();
  };

  const FormSchema = z.object({
    country: z.string().min(2, {
      message: "Please select your country",
    }),
    addressLine1: z.string().min(2, { message: "Please enter your street address" }),
    addressLine2: z.string().optional(),
    city: z.string().min(2, { message: "Please enter your city" }),
    stateProvince: z.string().min(2, { message: "Please enter your state or province" }),
    postalCode: z.string().min(5, { message: "Please enter your ZIP or postal code" }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      country: address?.country || "",
      addressLine1: address?.addressLine1 || "",
      addressLine2: address?.addressLine2 || "",
      city: address?.city || "",
      stateProvince: address?.stateProvince || "",
      postalCode: address?.postalCode || "",
    },
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleAddressSave)} className="space-y-2">
        {/* country */}
        <FormField
          control={form.control}
          name="country"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country *</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select your country" />
                  </SelectTrigger>
                  <SelectContent>
                    {ALL_COUNTRIES.map((c) => (
                      <SelectItem key={c} value={c}>
                        {c}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* addressLine1 */}
        <FormField
          control={form.control}
          name="addressLine1"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* addressLine2 */}
        <FormField
          control={form.control}
          name="addressLine2"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Line 2 (optional)</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* city */}
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>City *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* state/province */}
        <FormField
          control={form.control}
          name="stateProvince"
          render={({ field }) => (
            <FormItem>
              <FormLabel>State/Province *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* postal code */}
        <FormField
          control={form.control}
          name="postalCode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>ZIP/Postal Code *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={
            deletingCreatorContacts ||
            deletingOwnerContacts ||
            addingCreatorContact ||
            addingOwnerContact
          }
          onBack={onClose}
          nextLabel="Save"
          backLabel="Cancel"
        />
      </form>
    </Form>
  );
}

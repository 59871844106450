import { cn } from "@/utils";
import { Loader2 } from "lucide-react";
import React from "react";
import { CredtentShield } from "./graphics/credtent-logo";

type PageLoaderProps = {
  overlay?: boolean;
  loading?: boolean;
  blur?: boolean;
};

export const PageLoader: React.FC<PageLoaderProps> = ({
  overlay = false,
  loading = true,
  blur = true,
}) => {
  const bouncingShield = (
    <div className="absolute left-1/2 top-1/3 z-[10001] w-24 -translate-x-1/2 text-primary dark:text-white">
      <div className="absolute animate-bounce">
        {/* animate-bounce cancels the -1/2w and -1/2h translation, thus we need to nest it in another div */}
        <CredtentShield scale={1.5} />
      </div>
    </div>
  );
  return loading ? (
    overlay ? (
      <div
        className={cn("fixed left-0 top-0 z-[10000] size-full bg-white/60", {
          "backdrop-blur-sm": blur,
        })}
      >
        {bouncingShield}
      </div>
    ) : (
      bouncingShield
    )
  ) : null;
};

export function Spinner({ className }: { className?: string }) {
  return <Loader2 className={cn("size-4 animate-spin", className)} />;
}

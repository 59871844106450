import React from "react";

interface MainProps {
  children: JSX.Element;
}
export const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <div
      className="mx-auto flex w-4/5 flex-1 flex-col overflow-scroll px-2 py-8 dark:bg-gray-900 lg:px-6 xl:px-8"
      id="main"
    >
      {children}
    </div>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LoginButton } from "@/components/buttons/login-button";
import { LogoutButton } from "@/components/buttons/logout-button";
import { SignupButton } from "@/components/buttons/signup-button";

export const NavBarButtons: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div>
      {isAuthenticated ? (
        <LogoutButton />
      ) : (
        <div className="flex gap-4">
          <SignupButton />
          <LoginButton />
        </div>
      )}
    </div>
  );
};

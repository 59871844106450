import { UploadIcon } from "lucide-react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Spinner } from "@/components/page-loader";
import { UserMedia, useUploadMediaMutation } from "@/generated-types";
import { cn } from "@/utils";

type MediaItem = Pick<UserMedia, "id" | "extension" | "sourceType">;

type BasicDropZoneProps = {
  maxSize: number;
  maxSizeText: string;
  onSuccess: (media: MediaItem[]) => void;
  onFailure: () => void;
  className?: string;
};

export function BasicDropzone({
  maxSize,
  maxSizeText,
  onSuccess,
  onFailure,
  className,
}: BasicDropZoneProps) {
  const [uploadMedia, { loading }] = useUploadMediaMutation();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        onFailure();
        return;
      }
      const { data } = await uploadMedia({
        variables: {
          upload: acceptedFiles[0],
          length: acceptedFiles[0].size,
        },
        context: {
          headers: { "X-Apollo-Operation-Name": "createUserMedia" },
        },
      });

      if (data?.uploadMedia?.status === "success" && data?.uploadMedia?.media)
        onSuccess([data?.uploadMedia?.media]); // TODO: support multiple uploads
    },
    [uploadMedia, onSuccess, onFailure]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"], // file extension check is case-insensitive
      "text/plain": [".txt"],
    },
  });

  const files = acceptedFiles.map((file: File) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <div
      {...getRootProps({
        className: cn(
          "dropzone size-64 bg-muted border-0 flex flex-col items-center justify-center gap-4 p-8 cursor-pointer",
          className
        ),
      })}
    >
      <input {...getInputProps()} />
      {loading ? <Spinner className="size-8" /> : <UploadIcon size={32} />}
      <p className="text-center text-sm">
        Drag and drop media files here, or click to select files
      </p>
      <p className="text-center text-xs">
        Accept JPEG, PNG, or TXT
        <br />
        Limit to {maxSizeText} per file.
      </p>
    </div>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Owner, OwnerTypes, useUpdateOwnerMutation } from "@/generated-types";
import { OWNER_TYPES_VALUES } from "@/utils/options";

type OwnerTypeMiniFormProps = {
  owner: Pick<Owner, "id" | "type"> | undefined;
  onClose?: () => void;
};

export function OwnerTypeMiniForm({ owner, onClose }: OwnerTypeMiniFormProps) {
  const [updateOwner, { loading: updatingOwner }] = useUpdateOwnerMutation();

  const FormSchema = z.object({
    type: z.enum(["", ...Object.keys(OWNER_TYPES_VALUES)]).optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      type: owner?.type || undefined,
    },
    mode: "onBlur",
  });

  const {
    formState: { isValid },
    setValue,
    watch,
  } = form;

  async function handleSubmit(data: z.infer<typeof FormSchema>) {
    await updateOwner({
      variables: {
        id: owner?.id!,
        data: {
          type: data.type as OwnerTypes,
        },
      },
    });
    onClose && onClose();
  }

  return (
    <Form {...form}>
      {/* our fields */}
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-2">
        {/* type */}
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select your type" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(OWNER_TYPES_VALUES).map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={updatingOwner}
          onBack={onClose}
          nextLabel="Save"
          backLabel="Cancel"
        />
      </form>
    </Form>
  );
}

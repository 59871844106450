import { cn } from "@/utils";

export const CONTENT_BADGES = [
  {
    id: "hcc-uncertified",
    label: "Human-Created Content",
    acronym: "HCC",
    description: "",
    type: "ORIGIN",
    certification: "UNCERTIFIED",
  },
  {
    id: "hcc-certified",
    label: "Human-Created Content",
    acronym: "HCC",
    description: "",
    type: "ORIGIN",
    certification: "CERTIFIED",
  },
  {
    id: "aac-uncertified",
    label: "AI-Assisted Content",
    acronym: "AAC",
    description: "",
    type: "ORIGIN",
    certification: "UNCERTIFIED",
  },
  {
    id: "aac-certified",
    label: "AI-Assisted Content",
    acronym: "AAC",
    description: "",
    type: "ORIGIN",
    certification: "CERTIFIED",
  },
  {
    id: "acc-uncertified",
    label: "AI-Created Content",
    acronym: "ACC",
    description: "",
    type: "ORIGIN",
    certification: "UNCERTIFIED",
  },
  {
    id: "esc-uncertified",
    label: "Ethically-Sourced Content",
    acronym: "ESC",
    description: "",
    type: "SOURCING",
    certification: "UNCERTIFIED",
  },
  {
    id: "esc-certified",
    label: "Ethically-Sourced Content",
    acronym: "ESC",
    description: "",
    type: "SOURCING",
    certification: "CERTIFIED",
  },
  {
    id: "gpc-uncertified",
    label: "Guardrail-Protected Content",
    acronym: "GPC",
    description: "",
    type: "SOURCING",
    certification: "UNCERTIFIED",
  },
  {
    id: "gpc-certified",
    label: "Guardrail-Protected Content",
    acronym: "GPC",
    description: "",
    type: "SOURCING",
    certification: "CERTIFIED",
  },
  {
    id: "smc-uncertified",
    label: "Self-Managed Content",
    acronym: "SMC",
    description: "",
    type: "SOURCING",
    certification: "UNCERTIFIED",
  },
];

function makeBadgeURL(acronym: string) {
  return `https://assets.credtent.org/${acronym}.svg`;
}

type SelectBadgeProps = {
  options: typeof CONTENT_BADGES;
  multi: boolean;
  value: string[];
  onChange: (newValue: string[]) => void;
};

export function SelectBadge({ options, value = [], multi, onChange }: SelectBadgeProps) {
  function handleToggle(id: string, select: boolean) {
    if (multi) {
      if (select) onChange([...value, id]);
      else onChange(value.filter((item) => item !== id));
    } else {
      if (select) onChange([id]);
      else onChange([]);
    }
  }

  return (
    <div className="flex gap-4">
      {options.map((item) => {
        const selected = value.includes(item.id);
        return (
          <div
            key={item.id}
            onClick={() => handleToggle(item.id, !selected)}
            className={cn("size-32 shrink-0 cursor-pointer [&_div]:hover:bg-primary/20", {
              "[&_div]:bg-primary [&_div]:hover:bg-primary/80": selected,
            })}
          >
            <img src={makeBadgeURL(item.acronym)} alt="" className="size-full" />
            <div className="mt-1 h-1 w-full bg-transparent" />
          </div>
        );
      })}
    </div>
  );
}

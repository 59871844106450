import {
  GenderTypes,
  OwnerTypes,
  UserType,
  GenerativeAiTypes,
  AutoCorrectToolsTypes,
  MachineLearningToolsTypes,
  OtherAiToolsTypes,
  RequestStatuses,
} from "@/generated-types";

export const genderOptions = [
  { value: "", label: "Select a gender" },
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "NONBINARY", label: "Non-Binary" },
  { value: "OTHER", label: "Other" },
];

export const GENDER_VALUES: { [key in GenderTypes]: string } = {
  [GenderTypes.Female]: "Female",
  [GenderTypes.Male]: "Male",
  [GenderTypes.Nonbinary]: "Non-binary",
  [GenderTypes.Other]: "Other",
};

export const USER_TYPE_VALUES: { [key in UserType]: string } = {
  [UserType.Creator]: "Creator",
  [UserType.Owner]: "Rights Owner",
  [UserType.Llm]: "LLM",
  [UserType.Collaborator]: "Collaborator",
};

export const OWNER_TYPES_VALUES: { [key in OwnerTypes]: string } = {
  [OwnerTypes.BooksAndEbooksAuthors]: "Books and Ebooks: Authors",
  [OwnerTypes.BooksAndEbooksLiteraryAgents]: "Books and Ebooks: Literary Agents",
  [OwnerTypes.BooksAndEbooksPublishers]: "Books and Ebooks: Publishers",
  [OwnerTypes.BooksAndEbooksSelfPublishingPlatforms]: "Books and Ebooks: Self-publishing Platforms",
  [OwnerTypes.FilmActors]: "Film: Actors",
  [OwnerTypes.FilmDirectors]: "Film: Directors",
  [OwnerTypes.FilmDistributionCompanies]: "Film: Distribution Companies",
  [OwnerTypes.FilmProducers]: "Film: Producers",
  [OwnerTypes.FilmScreenwriters]: "Film: Screenwriters",
  [OwnerTypes.FilmStudios]: "Film: Studios",
  [OwnerTypes.GamesDistributionServices]: "Games: Distribution Services",
  [OwnerTypes.GamesGameDevelopers]: "Games: Game Developers",
  [OwnerTypes.GamesIpHolders]: "Games: IP Holders",
  [OwnerTypes.GamesPlatforms]: "Games: Platforms",
  [OwnerTypes.GamesPublishers]: "Games: Publishers",
  [OwnerTypes.MusicMechanicalRightsAgencies]: "Music: Mechanical Rights Agencies",
  [OwnerTypes.MusicMusicPublishers]: "Music: Music Publishers",
  [OwnerTypes.MusicPerformers]: "Music: Performers",
  [OwnerTypes.MusicProsPerformanceRightsOrganizations]:
    "Music: PROs (Performance Rights Organizations)",
  [OwnerTypes.MusicRecordLabels]: "Music: Record Labels",
  [OwnerTypes.MusicSongwriters]: "Music: Songwriters",
  [OwnerTypes.NewslettersIndependentPublishers]: "Newsletters: Independent Publishers",
  [OwnerTypes.NewslettersMediaCompanies]: "Newsletters: Media Companies",
  [OwnerTypes.NewslettersPublishingPlatforms]: "Newsletters: Publishing Platforms",
  [OwnerTypes.NewslettersWriters]: "Newsletters: Writers",
  [OwnerTypes.OnlineVideoContentCreators]: "Online Video: Content Creators",
  [OwnerTypes.OnlineVideoMcns]: "Online Video: MCNs",
  [OwnerTypes.OnlineVideoPlatforms]: "Online Video: Platforms",
  [OwnerTypes.OnlineVideoProductionCompanies]: "Online Video: Production Companies",
  [OwnerTypes.OnlineVideoRightsManagementAgencies]: "Online Video: Rights Management Agencies",
  [OwnerTypes.PodcastsAdvertisers]: "Podcasts: Advertisers",
  [OwnerTypes.PodcastsDistributionPlatforms]: "Podcasts: Distribution Platforms",
  [OwnerTypes.PodcastsNetworks]: "Podcasts: Networks",
  [OwnerTypes.PodcastsPodcastCreators]: "Podcasts: Podcast Creators",
  [OwnerTypes.PodcastsProductionCompanies]: "Podcasts: Production Companies",
  [OwnerTypes.SocialMediaContentBrands]: "Social Media Content: Brands",
  [OwnerTypes.SocialMediaContentContentCreators]: "Social Media Content: Content Creators",
  [OwnerTypes.SocialMediaContentInfluencerAgencies]: "Social Media Content: Influencer Agencies",
  [OwnerTypes.SocialMediaContentPlatforms]: "Social Media Content: Platforms",
  [OwnerTypes.SocialMediaContentRightsManagementServices]:
    "Social Media Content: Rights Management Services",
};

export const GENERATIVE_AI_VALUES: { [key in GenerativeAiTypes]: string } = {
  [GenerativeAiTypes.NotUsed]: "Not Used",
  [GenerativeAiTypes.UsedInContentExecution]: "Used in Content Execution",
  [GenerativeAiTypes.UsedInContentPlanning]: "Used in Content Planning",
  [GenerativeAiTypes.UsedInEditing]: "Used in Editing",
  [GenerativeAiTypes.UsedInPromotion]: "Used in Promotion",
  [GenerativeAiTypes.UsedInResearch]: "Used in Research",
};

export const AUTO_CORRECT_VALUES: { [key in AutoCorrectToolsTypes]: string } = {
  [AutoCorrectToolsTypes.NotUsed]: "Not Used",
  [AutoCorrectToolsTypes.UsedInWritingEditing]: "Used in Writing/Editing",
};

export const MACHINE_LEARNING_VALUES: { [key in MachineLearningToolsTypes]: string } = {
  [MachineLearningToolsTypes.NotUsed]: "Not Used",
  [MachineLearningToolsTypes.UsedInContentOptimization]: "Used in Content Optimization",
  [MachineLearningToolsTypes.UsedInDataAnalysis]: "Used in Data Analysis",
};

export const OTHER_AI_VALUES: { [key in OtherAiToolsTypes]: string } = {
  [OtherAiToolsTypes.NotUsed]: "Not Used",
  [OtherAiToolsTypes.Used]: "Used",
};

export const REQUEST_STATUS_VALUES: { [key in RequestStatuses]: string } = {
  [RequestStatuses.RequestApproved]: "Approved",
  [RequestStatuses.RequestCreating]: "Creating",
  [RequestStatuses.RequestRejected]: "Rejected",
  [RequestStatuses.RequestRevised]: "Revised",
  [RequestStatuses.RequestSubmitted]: "Submitted",
};

// source: extracted from "react-country-region-selector": "^3.6.1",
export const ALL_COUNTRIES = [
  "United States",
  "United Kingdom",
  "Canada",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo, Republic of the (Brazzaville)",
  "Congo, the Democratic Republic of the (Kinshasa)",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire, Republic of",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Islas Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern and Antarctic Lands",
  "Gabon",
  "Gambia, The",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela, Bolivarian Republic of",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

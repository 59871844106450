import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { FrownIcon, Loader2, MehIcon, SmileIcon } from "lucide-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { ReactionType, useCreateUserFeedbackMutation, useGetProfileQuery } from "@/generated-types";

export function FeedbackDialog({ trigger }: { trigger: React.ReactNode }) {
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  return (
    <Dialog open={isFeedbackDialogOpen} onOpenChange={setIsFeedbackDialogOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="w-full sm:max-w-2xl">
        <DialogHeader>
          <DialogTitle>Leave Feedback</DialogTitle>
          <DialogDescription className="text-sm">
            Credtent is currently in beta, so we appreciate your feedback.
          </DialogDescription>
        </DialogHeader>
        <FeedbackForm onClose={() => setIsFeedbackDialogOpen(false)} />
      </DialogContent>
    </Dialog>
  );
}

const FormSchema = z.object({
  reaction: z.enum([ReactionType.Dislike, ReactionType.Neutral, ReactionType.Like]).optional(),
  feedback: z.string(),
});

type FormValueType = z.infer<typeof FormSchema>;

function FeedbackForm({ onClose }: { onClose: () => void }) {
  const { data: profileData } = useGetProfileQuery();
  const [createFeedback, { loading }] = useCreateUserFeedbackMutation();

  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      feedback: "",
    },
    mode: "onBlur",
  });

  const {
    formState: { isValid },
    watch,
  } = form;

  async function doCreateFeedback(data: FormValueType) {
    await createFeedback({
      variables: {
        userId: profileData?.me?.id!,
        ...data,
      },
    });
    toast.success("Thank you for your feedback!");
    onClose();
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(doCreateFeedback)} className="space-y-4">
        {/* feedback */}
        <FormField
          control={form.control}
          name="feedback"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  {...field}
                  rows={8}
                  required
                  placeholder="Please let us know what you think."
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <FormField
            control={form.control}
            name="reaction"
            render={({ field }) => (
              <FormControl>
                <ToggleGroup
                  type="single"
                  value={field.value}
                  onValueChange={field.onChange}
                  className="gap-2"
                >
                  <ToggleGroupItem
                    size="sm"
                    variant="outline"
                    value={ReactionType.Dislike}
                    aria-label="Toggle dislike"
                  >
                    <FrownIcon className="size-4" />
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    size="sm"
                    variant="outline"
                    value={ReactionType.Neutral}
                    aria-label="Toggle neutral"
                  >
                    <MehIcon className="size-4" />
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    size="sm"
                    variant="outline"
                    value={ReactionType.Like}
                    aria-label="Toggle like"
                  >
                    <SmileIcon className="size-4" />
                  </ToggleGroupItem>
                </ToggleGroup>
              </FormControl>
            )}
          />

          <div className="flex grow justify-end space-x-2">
            <Button size="sm" type="button" variant="outline" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button size="sm" type="submit" disabled={!isValid || loading}>
              {loading && <Loader2 className="mr-2 size-4 animate-spin" />}
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}

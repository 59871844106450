import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React from "react";

import { AddressForm } from "@/components/forms/address-mini-form";
import { CreatorNameForm } from "@/components/forms/creator-name-form";
import { GenderMiniForm } from "@/components/forms/gender-mini-form";
import { CreatorContact, GetCreatorByAccountQuery, PlanTypes, User } from "@/generated-types";
import { PlanList } from "@/pages/SubscribePage/onboarding-subscript-plans";
import { formatAddress, formatGender, formatLegalName } from "@/utils/formatter";
import EditPicture from "./edit-picture";
import { TagsEditor } from "./tags-editor";
import ValueBox from "./value-box";

type CreatorItemType = GetCreatorByAccountQuery["creators"][number];

type ViewProfileProps = {
  loading: boolean;
  user: Pick<User, "userId" | "email" | "picture"> | undefined | null;
  plan: PlanTypes | undefined | null;
  creator: CreatorItemType | undefined;
  contact: Partial<CreatorContact> | undefined;
  onSavePicture: (pictureData: string) => void | Promise<void>;
  onSaveField: (
    field: string,
    value: string | undefined
  ) => string | undefined | Promise<string | undefined>;
  onSaveTags: (tags: string | undefined) => string | undefined | Promise<string | undefined>;
};

export const ViewCreatorProfile: React.FC<ViewProfileProps> = ({
  loading,
  user,
  plan,
  creator,
  contact,
  onSavePicture,
  onSaveField,
  onSaveTags,
}) => {
  const creatorNames: string[] = creator?.nicknames ? creator.nicknames.split(",") : [];

  // tags are filtered before display, just in case a tag was added before it is blocked by the site admin
  const tags: string[] =
    creator?.tags
      ?.filter((tagItem) => !tagItem.blocked && tagItem.title)
      .map((tagItem) => tagItem.title!) || [];

  return (
    <div className="my-8 flex flex-col ">
      <div className="flex flex-row items-center gap-2 text-sm font-light">
        <EyeIcon className="size-4 text-gray-400" />
        denotes information will be displayed publicly
      </div>
      <div className="flex flex-row items-center gap-2 text-sm font-light">
        <EyeSlashIcon className="size-4 text-gray-400" />
        denotes information will NOT be displayed publicly
      </div>

      <div className="mt-4 grid w-full grid-flow-row-dense grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-8">
        <ValueBox
          label="Profile Picture"
          publicViewable
          className="col-span-2 row-span-4"
          editTrigger={
            <EditPicture onSave={onSavePicture} className="-my-1.5 cursor-pointer p-2" />
          }
        >
          <div className="flex size-full items-center justify-center p-4">
            {loading ? (
              <div className="size-[200px]" />
            ) : (
              <img
                src={
                  creator?.profilePicture || user?.picture || "/assets/avatar-placeholder-400.png"
                }
                className="aspect-square size-[200px] max-w-full object-cover"
                alt="profile"
              />
            )}
          </div>
        </ValueBox>

        <ValueBox
          label="Your Legal Name"
          value={formatLegalName(creator)}
          publicViewable
          customEditor={<CreatorNameForm creator={creator} />}
          customActions
          className="col-span-2 row-span-1"
        />
        <ValueBox label="Email" readonly value={user?.email} />
        <ValueBox
          label="Gender"
          publicViewable
          value={formatGender(creator)}
          customEditor={<GenderMiniForm creator={creator} />}
          customActions
        />
        <ValueBox
          label="Pronouns"
          publicViewable
          value={creator?.pronouns}
          onSave={(v) => onSaveField("pronouns", v)}
        />

        <ValueBox
          label="Bio"
          publicViewable
          className="col-span-2 row-span-4"
          value={creator?.bio}
          onSave={(v) => onSaveField("bio", v)}
        />

        <ValueBox
          label="Creator Names"
          remarks="Alternate names you use on content channels."
          customEditor={
            <TagsEditor
              value={creatorNames}
              placeholder="Name, name"
              pattern={/^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;"'<>?/\\|~`.-]{1,30}$/}
            />
            // allow letters, numbers, spaces, and punctuation except for commas
          }
          value={creatorNames.join(", ")}
          onSave={(v) => onSaveField("nicknames", v)}
          publicViewable
          className="col-span-2 row-span-3"
        />

        <ValueBox
          label="Creator Tags"
          publicViewable
          customEditor={<TagsEditor value={tags} placeholder="Tag1, tag2" />}
          value={tags.join(", ")}
          onSave={(v) => onSaveTags(v)}
          className="col-span-2 row-span-3"
        />
        <ValueBox
          label="Address"
          customEditor={<AddressForm entity="creator" entityId={creator?.id} address={contact} />}
          customActions // don't render the default X and V buttons, the AddressForm renders its own Cancel and Save
          className="col-span-2"
        >
          {formatAddress(contact)}
        </ValueBox>

        <ValueBox readonly label="Subscription Plan">
          <PlanList plan={plan} />
        </ValueBox>
      </div>
    </div>
  );
};

import { useState } from "react";
import { Loader2, CircleHelp, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { cn } from "@/utils";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  HoverCardPortal,
} from "@/components/ui/hover-card";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

/**
 * Cancel/Save or Prev/Next button combo
 */
type FormActionsProps = {
  loading?: boolean;
  deleting?: boolean;
  disabled?: boolean;
  backLabel?: string;
  nextLabel?: string;
  deleteLabel?: string;
  onBack?: () => void;
  onNext?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  className?: string;
};

export function FormActions({
  nextLabel = "Next",
  backLabel = "Back",
  deleteLabel = "Delete",
  loading,
  deleting,
  disabled,
  onBack,
  onNext,
  onDelete,
  className,
}: FormActionsProps) {
  return (
    <div className={cn("flex", className)}>
      {onDelete && (
        <Button type="button" variant="destructive" onClick={onDelete}>
          {deleting && <Loader2 className="mr-2 size-4 animate-spin" />}
          {deleteLabel}
        </Button>
      )}
      <div className="flex grow justify-end space-x-4">
        {onBack && (
          <Button type="button" variant="outline" onClick={onBack} disabled={loading}>
            {backLabel}
          </Button>
        )}
        <Button type={onNext ? "button" : "submit"} onClick={onNext} disabled={disabled || loading}>
          {loading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {nextLabel}
        </Button>
      </div>
    </div>
  );
}

/**
 * A circled question mark that shows more information when hovered or tapped on
 */
type TellMeMoreProps = {
  open?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export function TellMeMore({ open, className, children }: TellMeMoreProps) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="flex w-full flex-row-reverse items-start gap-2"
    >
      <CollapsibleTrigger asChild>
        {isOpen ? (
          <Button variant="ghost" size="sm" className="w-9 p-0">
            <X className="size-4" stroke="#290099" />
          </Button>
        ) : (
          <Button variant="ghost" size="sm" className="absolute w-9 p-0">
            <CircleHelp className="size-4" stroke="#290099" />
          </Button>
        )}
      </CollapsibleTrigger>
      <CollapsibleContent
        className={cn(
          "flex-1 space-y-1 text-sm text-muted-foreground [&_*]:text-sm [&_*]:text-muted-foreground",
          className
        )}
      >
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
}

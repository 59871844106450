import React from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { Toaster as Sonner } from "sonner";

import GqlProvider from "./apolloClientProvider";
import { App } from "./app-routes";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./global.css";
import "./ZZZindex.css";

const tagManagerArgs = {
  gtmId: "GTM-W9PH5VM8",
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0ProviderWithNavigate>
      <GqlProvider>
        <App />
        <Toaster />
      </GqlProvider>
    </Auth0ProviderWithNavigate>
  </React.StrictMode>
);

/**
 * Taken out of @/components/ui/sonner.tsx
 * Importing from the above file would freeze the browser for some unknown reason
 * For usage see https://sonner.emilkowal.ski/ and https://ui.shadcn.com/docs/components/sonner
 */
function Toaster() {
  return (
    <Sonner
      position="bottom-center"
      richColors
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton: "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton: "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
    />
  );
}

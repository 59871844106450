import {
  GetChannelByIdQuery,
  GetProfileQuery,
  GetWorkByIdQuery,
  PlanTypes,
} from "@/generated-types";
import { PlanList } from "@/pages/SubscribePage/onboarding-subscript-plans";

type LicensingFormProps = {
  typeOfContent: "channel" | "work";
  profile: GetProfileQuery["me"];
  channel?: GetChannelByIdQuery["channels"][number];
  work?: GetWorkByIdQuery["works"][number];
  onClose?: () => void;
};

export function LicensingForm({
  typeOfContent,
  profile,
  channel: existingChannel,
  work: existingWork,
}: LicensingFormProps) {
  return (
    <div className="space-y-2">
      <PlanList plan={PlanTypes.Dnt} />
    </div>
  );
}

import { Label } from "@/components/ui/label";
import { useRecentCreatorsQuery, useRecentOwnersQuery } from "@/generated-types";
import { StakeholderItem } from "./stakeholder-list";
import { type Stakeholder } from "./types";

type RecentCreatorsProps = {
  typeOfStakeholder: "creator" | "rights owner";
  onSelect: (item: Stakeholder) => void;
};

export function RecentCreators({ typeOfStakeholder, onSelect }: RecentCreatorsProps) {
  const idString = window.localStorage.getItem(typeOfStakeholder) as string;
  const idArray = idString ? JSON.parse(idString) : undefined;

  const { data: creatorsData } = useRecentCreatorsQuery({
    variables: { ids: idArray },
    skip: !idArray && typeOfStakeholder !== "creator",
  });

  const { data: ownersData } = useRecentOwnersQuery({
    variables: { ids: idArray },
    skip: !idArray && typeOfStakeholder !== "rights owner",
  });

  const items = typeOfStakeholder === "creator" ? creatorsData?.creators : ownersData?.owners;

  // console.log({ idString, idArray, ownersData, items });

  return items ? (
    <div>
      {/* TODO: do better with pluralization if we keep this language */}
      <Label>You recently selected these {typeOfStakeholder}s:</Label>
      <div className="space-y-4 divide-y">
        {items.map((item) => (
          <StakeholderItem key={item.id} item={item} onSelect={onSelect} className="pt-4" />
        ))}
      </div>
    </div>
  ) : null;
}

export function useRecentStakeholders() {
  function addToRecent(type: "creator" | "rights owner", id: string) {
    const idString = window.localStorage.getItem(type) as string;
    const idArray: string[] = idString ? JSON.parse(idString) : [];
    const removeGivenId = idArray.filter((item) => item !== id);
    const prependGivenId = [id, ...removeGivenId];
    const trimToLength = prependGivenId.slice(0, 5);
    window.localStorage.setItem(type, JSON.stringify(trimToLength));
  }

  return { addToRecent };
}

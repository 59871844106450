import first from "lodash/first";
import { toast } from "sonner";
import tw from "tailwind-styled-components";

import { Label } from "@/components/ui/label";
import {
  GetChannelByIdQuery,
  GetProfileQuery,
  GetWorkByIdQuery,
  useUpdateChannelMutation,
  useUpdateWorkMutation,
} from "@/generated-types";
import { formatLegalName } from "@/utils/formatter";
import { StakeholderList } from "./stakeholder-list";
import { Stakeholder } from "./types";

type StakeholdersFormProps = {
  typeOfContent: "channel" | "work";
  profile: GetProfileQuery["me"];
  channel?: GetChannelByIdQuery["channels"][number];
  work?: GetWorkByIdQuery["works"][number];
  onClose?: () => void;
};

export function StakeholderForm({
  typeOfContent,
  profile,
  channel: existingChannel,
  work: existingWork,
  onClose,
}: StakeholdersFormProps) {
  const [updateChannel, { loading: updatingChannel }] = useUpdateChannelMutation({
    refetchQueries: ["GetChannelById", "GetChannelsByCreator", "GetChannelsByOwner"],
  });
  const [updateWork, { loading: updatingWork }] = useUpdateWorkMutation({
    refetchQueries: ["GetWorkById", "GetWorksByCreator", "GetWorksByOwner"],
  });

  // current user's Creator ID or Rights Owner ID
  // One of them should have value
  const userCreatorId = first(profile?.account?.creators)?.id;
  const userOwnerId = profile?.account?.owner?.id;

  const creators = existingChannel?.creators || existingWork?.creators;
  const owners = existingChannel?.owners || existingWork?.owners;

  function showToast(
    field: "creators" | "owners",
    operation: "connect" | "disconnect",
    item: Stakeholder
  ) {
    function handleUndo() {
      updateStakeholder(field, operation === "connect" ? "disconnect" : "connect", item);
    }

    const displayName =
      "name" in item
        ? item.name
        : "firstName" in item
          ? formatLegalName(item, /*initial*/ true)
          : "";

    const message = `${operation === "connect" ? "Added" : "Deleted"} ${field === "creators" ? "creator" : "rights owner"} ${displayName} ${operation === "connect" ? "to" : "from"} ${typeOfContent}.`;
    toast.success(message, {
      action: {
        label: "Undo",
        onClick: handleUndo,
      },
    });
  }

  async function updateStakeholder(
    field: "creators" | "owners",
    operation: "connect" | "disconnect",
    item: Stakeholder
  ) {
    if (typeOfContent === "channel" && existingChannel) {
      await updateChannel({
        variables: {
          where: { id: existingChannel.id },
          update: {
            [field]: {
              [operation]: [{ where: { node: { id: item.id } } }],
            },
          },
        },
      });
    } else if (typeOfContent === "work" && existingWork) {
      await updateWork({
        variables: {
          where: { id: existingWork.id },
          update: {
            [field]: {
              [operation]: [{ where: { node: { id: item.id } } }],
            },
          },
        },
      });
    }
    showToast(field, operation, item);
  }

  async function handleAddCreator(value: Stakeholder) {
    return updateStakeholder("creators", "connect", value);
  }

  async function handleDeleteCreator(value: Stakeholder) {
    return updateStakeholder("creators", "disconnect", value);
  }

  async function handleAddOwner(value: Stakeholder) {
    return updateStakeholder("owners", "connect", value);
  }

  async function handleDeleteOwner(value: Stakeholder) {
    return updateStakeholder("owners", "disconnect", value);
  }

  return (
    <div>
      <Label className="text-base">Who created this {typeOfContent}?</Label>
      <StakeholderList
        type="creator"
        value={creators || []}
        userCreatorId={userCreatorId}
        userOwnerId={userOwnerId}
        onAdd={handleAddCreator}
        onDelete={handleDeleteCreator}
        loading={updatingChannel || updatingWork}
      />

      <Divider className="mb-6 mt-8" />

      <Label className="text-base">Who owns this {typeOfContent}?</Label>
      <p className="text-sm text-muted-foreground">
        List the rights owners only if this {typeOfContent} is NOT owned by the creator or creators.
      </p>
      <StakeholderList
        type="rights owner"
        value={owners || []}
        userCreatorId={userCreatorId}
        userOwnerId={userOwnerId}
        onAdd={handleAddOwner}
        onDelete={handleDeleteOwner}
        loading={updatingChannel || updatingWork}
      />
    </div>
  );
}

export const Divider = tw.div`
 shrink-0 bg-border h-[4px] w-full
`;

import { CheckIcon, CreditCardIcon, UserIcon } from "@heroicons/react/20/solid";
import React from "react";

import { useGetAccountDateQuery, useGetProfileQuery, UserType } from "@/generated-types";
import { cn } from "@/utils";
import { USER_TYPE_VALUES } from "@/utils/options";
import { parseISO } from "date-fns";

interface Activity {
  id: number;
  content: string;
  target: string;
  href: string;
  date: string;
  datetime: string;
  icon: React.ElementType;
  iconBackground: string;
}

interface ActivityFeedProps {
  activities: string[];
  className?: string;
}

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ activities, className }) => {
  /* For now this is hacky because we don't have notfications, just using todays date and sample events */
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();
  const { data: accountDate } = useGetAccountDateQuery();

  const accountCreatedAt = accountDate?.me?.account?.createdAt
    ? parseISO(accountDate.me.account.createdAt)
    : undefined;
  const formattedDate = accountCreatedAt
    ? accountCreatedAt.toLocaleDateString("en-US", { month: "short", day: "numeric" })
    : "";

  const CreatedEvent = activities.includes("created")
    ? {
        id: 1,
        content: "Created",
        target: "Credtent Account",
        href: "#",
        date: formattedDate,
        datetime: "2024-03-01",
        icon: UserIcon,
        iconBackground: "bg-gray-400",
      }
    : {};

  const PaymentEvent = activities.includes("payment")
    ? {
        id: 2,
        content: "Verified Payment",
        target: "Stripe",
        href: "#",
        date: formattedDate,
        datetime: "2024-03-01",
        icon: CreditCardIcon,
        iconBackground: "bg-blue-500",
      }
    : {};
  const IdentityEvent = activities.includes("profile")
    ? {
        id: 3,
        content: "Created Profile as",
        target: USER_TYPE_VALUES[profileData?.me?.userType as UserType],
        href: "#",
        date: formattedDate,
        datetime: "2024-03-01",
        icon: CheckIcon,
        iconBackground: "bg-green-500",
      }
    : {};

  const activity = [CreatedEvent, PaymentEvent, IdentityEvent];
  const filteredActivity = activity.filter((event) => Object.keys(event).length !== 0);
  return (
    <div className={cn("flow-root", className)}>
      <ul className="-mb-8">
        {activity.map((event, eventIdx) => (
          <li key={eventIdx}>
            <div className="relative pb-8">
              {eventIdx !== activity.length - 1 ? (
                <span
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={cn(
                      event.iconBackground,
                      "flex size-8 items-center justify-center rounded-full ring-8 ring-white"
                    )}
                  >
                    {event.icon && <event.icon className="size-5 text-white" aria-hidden="true" />}
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}{" "}
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <time dateTime={event.datetime}>{event.date}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

import { cn } from "@/utils";
import ellipsize from "ellipsize";
import { lazy, Suspense } from "react";

const LazyInlineIcon = lazy(() => import("../../icons"));

type CategoryIconProps = {
  icon: string | undefined | null;
  size: number;
  className?: string;
};

export function CategoryIcon({ icon, size, className }: CategoryIconProps) {
  const possibleSVG = icon?.startsWith("<?xml") || icon?.startsWith("<svg");

  if (possibleSVG && icon)
    return (
      <div
        dangerouslySetInnerHTML={{ __html: icon }}
        style={{ width: size, height: size }}
        className={cn("[&_svg]:size-full [&_svg]:fill-current", className)}
      />
    );
  else {
    // const IconKey = icon?.replace("-icon", "") ?? "art-board";
    // const Icon = iconMapTyped[IconKey] ?? iconMapTyped["art-board"];
    // return <Icon width={size} height={size} fill="currentColor" className={className} />;
    return (
      <Suspense fallback={<div style={{ width: size, height: size }}></div>}>
        <LazyInlineIcon size={size} icon={icon} className={className} />
      </Suspense>
    );
  }
}

type CategoryIconWithLabelProps = {
  cat: { title?: string | null | undefined; icon?: string | null | undefined } | undefined;
  size?: "lg" | "sm";
  className?: string;
};

export function CategoryIconWithLabel({ cat, size, className }: CategoryIconWithLabelProps) {
  if (!cat) return null;
  const sizePx = size === "lg" ? 64 : 24;
  return (
    <div key={cat.title} className={cn(className, "flex flex-col text-purple-800")}>
      <CategoryIcon icon={cat.icon} size={sizePx} className="self-center" />
      <div
        className={cn(
          size === "lg" ? "mt-4 text-base" : "mt-2 text-xs",
          "text-center leading-tight"
        )}
      >
        {cat.title ? ellipsize(cat.title, 25) : null}
      </div>
    </div>
  );
}

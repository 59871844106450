import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

export default function RootLayout() {
  const location = useLocation();
  useEffect(() => {
    // scroll to the top of the page when the user navigates
    document.getElementById("main")?.scrollTo(0, 0);

    // GTM stuff
    const pageViewDataLayer = {
      event: "pageview",
      page: location.pathname,
    };

    TagManager.dataLayer({
      dataLayer: pageViewDataLayer,
    });
  }, [location]);

  return <Outlet />;
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Creator, useUpdateCreatorMutation } from "@/generated-types";

type CreatorNameFormProps = {
  creator: Pick<Creator, "id" | "firstName" | "lastName" | "middleName"> | undefined;
  onClose?: () => void;
};

export function CreatorNameForm({ creator, onClose }: CreatorNameFormProps) {
  const [updateCreator, { data: updateCreatorResult, loading: updatingCreator }] =
    useUpdateCreatorMutation();

  const handleSave = async (data: z.infer<typeof FormSchema>) => {
    const { firstName, lastName, middleName } = data;
    await updateCreator({
      variables: {
        id: creator?.id!, // ID must exist
        data: {
          firstName,
          lastName,
          middleName,
          firstNameLC: firstName.toLowerCase(),
          lastNameLC: lastName.toLowerCase(),
        },
      },
    });

    onClose && onClose();
  };

  const FormSchema = z.object({
    firstName: z.string().min(2, {
      message: "Please enter your first name",
    }),
    middleName: z.string().optional(),
    lastName: z.string().min(2, {
      message: "Please enter your last name",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: creator?.firstName || "",
      middleName: creator?.middleName || "",
      lastName: creator?.lastName || "",
    },
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} className="space-y-2">
        {/* first name */}
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name *</FormLabel>
              <FormControl>
                <Input {...field} autoCapitalize="words" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* middle name */}
        <FormField
          control={form.control}
          name="middleName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Middle Name (or Initial)</FormLabel>
              <FormControl>
                <Input {...field} autoCapitalize="words" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* last name */}
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name *</FormLabel>
              <FormControl>
                <Input {...field} autoCapitalize="words" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={updatingCreator}
          onBack={onClose}
          nextLabel="Save"
          backLabel="Cancel"
        />
      </form>
    </Form>
  );
}

import { useAuth0 } from "@auth0/auth0-react";
import { Dialog } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { XMarkIcon as XMarkIconOutline } from "@heroicons/react/24/outline";
import first from "lodash/first";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { CredentLogo } from "@/components/graphics/credtent-logo";
import { AccountStatus, OnboardingStatus, useGetProfileQuery, UserType } from "@/generated-types";
import { hasIDVerification } from "@/utils/config";
import { NavBarButtons } from "./nav-btns";

interface Props {
  hideActions?: boolean;
  logoLink?: string;
}

const NEXT_ROUTE: { [key in OnboardingStatus]: string } = {
  [OnboardingStatus.UserCreated]: "/onboarding/terms",
  [OnboardingStatus.TermsAccepted]: "/onboarding/subscribe",
  [OnboardingStatus.SubscriptionPaid]: hasIDVerification()
    ? "/onboarding/idverify"
    : "/onboarding/profile",
  [OnboardingStatus.IdVerified]: "/onboarding/profile",
};

export const NavBar: React.FC<Props> = ({ hideActions, logoLink }) => {
  const { isAuthenticated, user, logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const { data: profileData } = useGetProfileQuery({
    skip: !isAuthenticated,
    onCompleted(data) {
      if (!data.me || !data.me.account) navigate("/onboarding/terms");
      else if (data.me.account.accountStatus === AccountStatus.Setup) {
        // confine the current page based on the user's onboarding progress
        const onboardingStatus = data?.me?.account?.onboardingStatus;
        if (onboardingStatus) {
          const nextRoute = NEXT_ROUTE[onboardingStatus];
          console.log({ nextRoute, location });
          if (nextRoute && nextRoute !== location.pathname) navigate(nextRoute);
        }
      } else if (data.me.account.creators.length === 0 && !data.me.account.owner) {
        // last line of defense: if for whatever reason the user has neither creator nor owner records, send the user back to onboarding
        const nextRoute = "/onboarding/profile";
        if (nextRoute !== location.pathname) navigate(nextRoute);
      }

      return data;
    },
  });

  const profilePicture =
    profileData?.me?.userType === UserType.Creator
      ? first(profileData?.me?.account?.creators)?.profilePicture
      : profileData?.me?.account?.owner?.profilePicture;
  const profilePageUri =
    profileData?.me?.userType === UserType.Creator ? "/profile/creator" : "/profile/rights-owner";

  const Avatar = () => {
    if (!user) {
      return <UserCircleIcon />;
    } else {
      return (
        <img
          src={profilePicture || profileData?.me?.picture || "/assets/avatar-placeholder-400.png"}
          alt="Profile"
          className="size-8 rounded-full"
        />
      );
    }
  };

  return (
    <header className="fixed inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10 bg-white dark:border-gray-300 dark:bg-gray-900">
      <div className="mx-auto flex w-full items-center justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex items-center gap-x-6">
          {logoLink ? (
            <Link to={logoLink}>
              <CredentLogo scale={0.4} />
            </Link>
          ) : (
            <CredentLogo scale={0.4} />
          )}
        </div>
        {!hideActions ? (
          <div className="flex flex-1 items-center justify-end gap-x-6">
            {isAuthenticated ? (
              <>
                {/* <Link
                  to="https://insigh.to/b/credtent.org"
                  target="_blank"
                  className="button bg-[#F98B25] font-normal text-white hover:bg-[#F98B25]/90"
                >
                  Share Your Feedback
                </Link> */}
                <Link to={profilePageUri} className="-m-1.5 p-1.5">
                  <span className="sr-only">Your profile</span>
                  <Avatar />
                </Link>
              </>
            ) : null}
            {/* <NavBurger /> // we will deal with mobile nav later */}
            <NavBarButtons />
          </div>
        ) : null}
        <Dialog open={isNotificationOpen} onClose={setIsNotificationOpen}>
          <Dialog.Panel className="fixed right-0 top-16 z-50 w-full overflow-y-auto bg-white px-4 pb-6 dark:bg-gray-900 dark:ring-gray-300 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button type="button" onClick={() => setIsNotificationOpen(false)}>
                <span className="sr-only">Close Notifications</span>
                <XMarkIconOutline className="size-6" aria-hidden="true" />
              </button>
              <div className="-ml-0.5">
                <a href="#" className="-m-1.5 block p-1.5">
                  <span className="sr-only">Notifications</span>
                  Notifications go here
                </a>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </header>
  );
};

import {
  BuildingLibraryIcon,
  CommandLineIcon,
  GlobeEuropeAfricaIcon,
  NewspaperIcon,
  UserIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";

import { FeedbackDialog } from "./feedback-form";

export const SideBar: React.FC = () => {
  const mainItems = [
    {
      icon: CommandLineIcon,
      label: "Overview",
      route: "/overview",
    },
    {
      icon: UserIcon,
      label: "Profile",
      route: "/profile",
    },
    {
      icon: BuildingLibraryIcon,
      label: "Content Library",
      route: "/content",
    },
  ];

  const extraItems = [
    {
      icon: NewspaperIcon,
      label: "Blog",
      route: "https://medium.com/credtent-on-content",
    },
    {
      icon: GlobeEuropeAfricaIcon,
      label: "Community",
      route: "https://app.heartbeat.chat/credtent/",
    },
  ];

  return (
    <div
      id="app-sidebar"
      className="sticky mt-16 hidden h-full w-[250px] flex-col justify-between gap-y-5 bg-white px-6 py-8 ring-1 ring-gray-900/10 dark:bg-gray-900 dark:ring-gray-700 sm:flex"
    >
      <nav className="">
        <ul className="flex flex-1 flex-col gap-y-7">
          {mainItems.map(({ icon: Icon, label, route }) => (
            <li key={route}>
              <Link
                to={route}
                className="flex flex-row items-center gap-2 text-gray-700 dark:text-gray-400"
              >
                <Icon className="size-5 dark:text-gray-400" aria-hidden="true" />
                {label}
              </Link>
            </li>
          ))}
          {/* <li><a className="flex flex-row items-center gap-2 text-gray-700 dark:text-gray-400" href="#"><CreditCardIcon className="h-5 w-5 dark:text-gray-400" aria-hidden="true" /> Balance</a></li> */}
        </ul>

        <div className="my-8 border-t-2 border-dashed border-gray-900/10 dark:border-gray-400" />

        <ul className="flex flex-1 flex-col gap-y-7">
          {extraItems.map(({ icon: Icon, label, route }) => (
            <li key={route}>
              <Link
                to={route}
                target="_blank"
                className="flex flex-row items-center gap-2 text-gray-700 dark:text-gray-400"
              >
                <Icon className="size-5 dark:text-gray-400" aria-hidden="true" />
                {label}
              </Link>
            </li>
          ))}
          <li>
            <FeedbackDialog
              trigger={
                <div className="flex cursor-pointer flex-row items-center gap-2 text-gray-700 dark:text-gray-400">
                  <ChatBubbleBottomCenterTextIcon
                    className="size-5 dark:text-gray-400"
                    aria-hidden="true"
                  />
                  Feedback
                </div>
              }
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";

import { AuthenticationGuard } from "./components/authentication-guard";
import { PageLoader } from "./components/page-loader";
import { PlanTypes } from "./generated-types";
import ProtectedLayout from "./layouts/protected-layout";
import PublicLayout from "./layouts/public-layout";
import RootLayout from "./layouts/root-layout";

// "essential pages"
import AddChannelPage from "./pages/AddChannelPage";
import AddContentPage from "./pages/content-page/add-content-page";
import AddSimilarPage from "./pages/content-page/add-similar-page";
import ContentPage from "./pages/content-page/view-content-page";
import DashboardPage from "./pages/DashboardPage";
import NotFoundPage from "./pages/NotFoundPage";
import ChooseProfilePage from "./pages/ProfilePage/choose-page";
import CreatorProfilePage from "./pages/ProfilePage/creator-profile-page";
import OwnerProfilePage from "./pages/ProfilePage/owner-profile-page";
import FAQPage from "./pages/public-home/faq-page";
import PublicHomePage from "./pages/public-home/public-home-page";

// lazy loading all onboarding pages to cut down bundle size
const OnboardingTermsPage = lazy(() => import("./pages/TncIntroPage/onboarding-terms-page"));
const IDVerifyPage = lazy(() => import("./pages/IDVerifyPage/onboarding-idverify-page"));
const SubscribePage = lazy(() => import("./pages/SubscribePage/onboarding-subscribe-page"));
const OnboardingCheckoutPage = lazy(() => import("./pages/SubscribePage/onboarding-checkout-page"));
const OnboardingProfileTypePage = lazy(() => import("./pages/onboarding/onb-profile-type-page"));
const OnboardingCreatorBioPage = lazy(() => import("./pages/onboarding/onb-creator-bio-page"));
const OnboardingCreatorMiscPage = lazy(() => import("./pages/onboarding/onb-creator-misc-page"));
const OnboardingCreatorNamePage = lazy(() => import("./pages/onboarding/onb-creator-name-page"));
const OnboardingOwnerNamePage = lazy(() => import("./pages/onboarding/onb-owner-name-page"));
const TermsPage = lazy(() => import("./pages/public-home/terms-page"));

// lazy loading certification pages
const CertificationSummary = lazy(() => import("./pages/certification/certification-summary"));
const CertReqPage = lazy(() => import("./pages/certification/certification-request-page"));
const CertEditorSummaryPage = lazy(() => import("./pages/certification/editor-summary"));
const CertEditorPage = lazy(() => import("./pages/certification/editor-page"));

export const App: React.FC = () => {
  // we switched to React Router's new browser router that supports Data API, for the purpose of using useBlocker()
  // to protect unsaved form data.
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootLayout />}>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<PublicHomePage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route
            path="/terms"
            element={
              <Suspense>
                <TermsPage />
              </Suspense>
            }
          />
          <Route path="/signup" element={<PublicHomePage action="signup" />} />
          <Route path="/login" element={<PublicHomePage action="login" />} />
          <Route path="/logout" element={<PublicHomePage action="logout" />} />
        </Route>

        {/* lazy loading all onboarding pages */}
        <Route path="/onboarding" element={<AuthenticationGuard component={Outlet} />}>
          <Route index element={<Navigate to="terms" />} />
          <Route
            path="terms"
            element={
              <Suspense>
                <OnboardingTermsPage />
              </Suspense>
            }
          />
          {/* <Route path="termsai" element={<TncAssistPage />} /> */}
          <Route
            path="subscribe"
            element={
              <Suspense>
                <SubscribePage />
              </Suspense>
            }
          />
          <Route path="checkout" element={<Outlet />}>
            <Route index element={<Navigate to="../subscribe" />} />
            <Route
              path=":plan"
              element={
                <Suspense>
                  <OnboardingCheckoutPage />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="idverify"
            element={
              <Suspense>
                <IDVerifyPage />
              </Suspense>
            }
          />
          <Route path="profile" element={<Outlet />}>
            <Route index element={<Navigate to="type" />} />
            <Route
              path="type"
              element={
                <Suspense>
                  <OnboardingProfileTypePage />
                </Suspense>
              }
            />
            <Route
              path="creator-name"
              element={
                <Suspense>
                  <OnboardingCreatorNamePage />
                </Suspense>
              }
            />
            <Route
              path="creator-bio"
              element={
                <Suspense>
                  <OnboardingCreatorBioPage />
                </Suspense>
              }
            />
            <Route
              path="creator-misc"
              element={
                <Suspense>
                  <OnboardingCreatorMiscPage />
                </Suspense>
              }
            />
            <Route
              path="rights-owner-name"
              element={
                <Suspense>
                  <OnboardingOwnerNamePage />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route element={<AuthenticationGuard component={ProtectedLayout} />}>
          <Route path="/overview" element={<DashboardPage />} />
          <Route path="/profile" element={<Outlet />}>
            <Route index element={<ChooseProfilePage />} />
            <Route path="creator" element={<CreatorProfilePage />} />
            <Route path="rights-owner" element={<OwnerProfilePage />} />
          </Route>
          <Route path="/content" element={<Outlet />}>
            <Route index element={<AddChannelPage />} />
            <Route path="add-content" element={<AddContentPage />} />
            <Route path="add-similar/:contentId" element={<AddSimilarPage />} />
            <Route path=":contentId">
              <Route index element={<Navigate to="basic-content-info" />} />
              <Route path="basic-content-info" element={<ContentPage tab="basic-content-info" />} />
              <Route
                path="creators-rights-owners"
                element={<ContentPage tab="creators-rights-owners" />}
              />
              <Route path="media" element={<ContentPage tab="media" />} />
              <Route path="licensing" element={<ContentPage tab="licensing" />} />
            </Route>
          </Route>

          {/* lazy loading certification page */}
          <Route path="/certification" element={<Outlet />}>
            <Route
              index
              element={
                <Suspense>
                  <CertificationSummary />
                </Suspense>
              }
            />
            <Route
              path="<new>"
              element={
                <Suspense>
                  <CertReqPage />
                </Suspense>
              }
            />
            <Route path=":id">
              <Route index element={<Navigate to="creator" />} />
              <Route
                path=":tab"
                element={
                  <Suspense>
                    <CertReqPage />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="/editor" element={<Outlet />}>
            <Route
              index
              element={
                <Suspense>
                  <CertEditorSummaryPage />
                </Suspense>
              }
            />
            <Route path=":id">
              <Route index element={<Navigate to="ai-usage" />} />
              <Route
                path=":tab"
                element={
                  <Suspense>
                    <CertEditorPage />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* try to fix an error on Arc browser that the OAuth callback is caught in Not Found */}
        <Route path="/callback" element={<PageLoader />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export type OnboardingCheckoutPageParams = {
  plan: PlanTypes;
};

export type ContentPageParams = {
  contentId: string;
};

export type CertReqPageParams = {
  id: string;
  tab?: string;
};

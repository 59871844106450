import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import first from "lodash/first";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ContentPageParams } from "@/app-routes";
import { PageLoader } from "@/components/page-loader";
import { useGetChannelByIdQuery, useGetProfileQuery, useGetWorkByIdQuery } from "@/generated-types";
import { MetadataForm } from "./content-metadata-form";

export default function AddSimilarPage() {
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();
  const { contentId } = useParams<ContentPageParams>();
  const navigate = useNavigate();

  const entityId = contentId?.substring(2);
  const typeOfContent = contentId?.charAt(0) === "c" ? "channel" : "work";

  const { data: channelData, loading: loadingChannel } = useGetChannelByIdQuery({
    variables: { channelId: entityId! },
    skip: typeOfContent !== "channel" || !entityId,
  });

  const { data: workData, loading: loadingWork } = useGetWorkByIdQuery({
    variables: { workId: entityId! },
    skip: typeOfContent !== "work" || !entityId,
  });

  const firstChannel = first(channelData?.channels);
  const firstWork = first(workData?.works);
  const title = firstChannel?.channelName || firstWork?.title;

  function handleComplete(type: "channel" | "work", id: string) {
    setTimeout(() => navigate(`/content/${type.charAt(0)}$${id}/creators-rights-owners`), 100);
  }

  return (
    <div className="max-w-4xl">
      <PageLoader overlay loading={loadingProfile} />

      <div className="">
        <h1 className="mb-2 text-3xl font-bold capitalize tracking-tight">Add {typeOfContent}</h1>
        <div className="mb-4 text-sm">
          <span className="font-normal">Similar to </span>
          <Link
            to={`/content/${contentId}/basic-content-info`}
            className="font-semibold text-primary"
          >
            {title}
          </Link>
        </div>
      </div>
      <Card className="rounded-md bg-muted">
        <CardHeader>
          <CardTitle>Basic Content Info</CardTitle>
          <CardDescription>
            Enter information about your content here. If you decide to license your content for AI
            training, having accurate information will likely make it stand out in discovery, and
            improve its relevancy.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <MetadataForm
            profile={profileData?.me}
            creatingSimilar
            channel={firstChannel}
            work={firstWork}
            onComplete={handleComplete}
          />
        </CardContent>
      </Card>
    </div>
  );
}

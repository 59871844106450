import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/overview",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={handleLogin} className="button block">
      Log in <span aria-hidden="true">&rarr;</span>
    </a>
  );
};

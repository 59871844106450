"use client";
/*
 * Documentation:
 * Vertical Stepper — https://app.subframe.com/library?component=Vertical+Stepper_bdc0291d-b5be-40c5-ae2f-527a868488b2
 */

import React from "react";
import { cn } from "@/utils";
import { CheckIcon } from "lucide-react";

interface StepProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "completed" | "active";
  stepNumber?: string;
  label?: string;
  firstStep?: boolean;
  lastStep?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Step = React.forwardRef<HTMLElement, StepProps>(function Step(
  {
    variant = "default",
    stepNumber,
    label,
    firstStep = false,
    lastStep = false,
    children,
    className,
    ...otherProps
  }: StepProps,
  ref
) {
  return (
    <div
      className={cn("flex h-full w-full items-start gap-3", className)}
      ref={ref as any}
      {...otherProps}
    >
      <div
        className={cn("flex flex-col items-center gap-1 self-stretch", {
          "h-auto w-auto flex-none": lastStep,
        })}
      >
        <div
          className={cn("flex h-2 w-0.5 flex-none flex-col items-center gap-2 bg-border", {
            "h-2 w-0.5 flex-none": lastStep,
            hidden: firstStep,
          })}
        />
        <div
          className={cn(
            "flex h-7 w-7 flex-none items-center justify-center overflow-hidden rounded-full bg-border",
            { "bg-primary": variant === "active" },
            { "bg-primary/20": variant === "completed" }
          )}
        >
          {stepNumber ? (
            <span
              className={cn("text-center text-sm", {
                "text-primary-foreground": variant === "active",
                hidden: variant === "completed",
              })}
            >
              {stepNumber}
            </span>
          ) : null}
          <CheckIcon
            className={cn("text-heading-3 font-heading-3 text-default-font hidden size-4", {
              "inline-flex text-primary": variant === "completed",
            })}
          />
        </div>
        <div
          className={cn(
            "flex min-h-[8px] w-0.5 shrink-0 grow basis-0 flex-col items-center gap-2 bg-border",
            { hidden: lastStep }
          )}
        />
      </div>
      <div
        className={cn("flex shrink-0 grow basis-0 flex-col items-center gap-1 py-4", {
          "px-0 pb-1 pt-4": lastStep,
          "px-0 pb-4 pt-1": firstStep,
        })}
      >
        {label ? (
          <span
            className={cn("w-full text-sm text-muted-foreground", {
              "font-semibold text-foreground": variant === "active",
              "text-foreground": variant === "completed",
            })}
          >
            {label}
          </span>
        ) : null}
        {children ? (
          <div
            className={cn("w-full", {
              hidden: variant !== "active",
            })}
          >
            {children}
          </div>
        ) : null}
      </div>
    </div>
  );
});

interface VerticalStepperRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const VerticalStepperRoot = React.forwardRef<HTMLElement, VerticalStepperRootProps>(
  function VerticalStepperRoot(
    { children, className, ...otherProps }: VerticalStepperRootProps,
    ref
  ) {
    return children ? (
      <div className={cn("flex flex-col items-start", className)} ref={ref as any} {...otherProps}>
        {children}
      </div>
    ) : null;
  }
);

export const VerticalStepper = Object.assign(VerticalStepperRoot, {
  Step,
});

import { useEffect } from "react";

// add the ability to refetch queries when the page regain focus, a feature from react-query
type RefetchOnFocusProps = {
  refetchers: Array<() => void>;
};

export function RefetchOnFocus({ refetchers }: RefetchOnFocusProps) {
  useEffect(() => {
    const handleFocus = () => {
      refetchers.forEach((fn) => fn());
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [refetchers]);

  return null;
}

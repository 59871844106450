import React, { useState } from "react";
import { NavBar } from "./navigation";
import { SideBar } from "./sidebar";
import { Main } from "./main";

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const handleDarkModeToggle = (newDarkMode: boolean) => {
    // Update the dark mode state in the PageLayout component
    console.log("PageLayout got new darkmod value", newDarkMode);
    setDarkMode(newDarkMode);
    console.log("Dark mode toggled to:", newDarkMode);
  };

  return (
    <div className={`min-h-full${darkMode ? " dark" : ""}`}>
      <NavBar />
      <div id="content" className="top-16 flex size-full flex-row">
        <SideBar />
        <Main children={children} />
      </div>
    </div>
  );
};

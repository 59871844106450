import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "@/utils";

export const HomePageNav = () => {
  return (
    <div className="mx-4 mb-5 flex gap-4">
      <CustomLink to="/">Overview</CustomLink>
      <CustomLink to="/faq">FAQ</CustomLink>
    </div>
  );
  // return (
  //   <div className="mb-5 flex gap-4">
  //     <button
  //       onClick={() => toggleVisibility("overview")}
  //       className={
  //         activeButton === "overview"
  //           ? "active-button"
  //           : "font-semiboldtext-center inline-flex items-center justify-center rounded-lg border border-gray-300 bg-transparent px-3 py-2 text-sm text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800"
  //       }
  //     >
  //       Overview
  //     </button>
  //     <button
  //       onClick={() => toggleVisibility("faq")}
  //       className={
  //         activeButton === "faq"
  //           ? "active-button"
  //           : "font-semiboldtext-center inline-flex items-center justify-center rounded-lg border border-gray-300 bg-transparent px-3 py-2 text-sm text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800"
  //       }
  //     >
  //       FAQ
  //     </button>
  //     {/* <button
  //   onClick={() => toggleVisibility("howItWorks")}
  //   className={
  //     activeButton === "howItWorks"
  //       ? "active-button"
  //       : "font-semiboldtext-center inline-flex items-center justify-center rounded-lg border border-gray-300 bg-transparent px-3 py-2 text-sm text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800"
  //   }
  // >
  //   How It Works
  // </button> */}
  //   </div>
  // );
};

const CustomLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={
        isActive
          ? "button active-button"
          : "font-semiboldtext-center inline-flex items-center justify-center rounded-lg border border-gray-300 bg-transparent px-3 py-2 text-sm text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800"
      }
    >
      {children}
    </Link>
  );
};

export default CustomLink;

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { MessageCirclePlus } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useCreateTagRequestMutation, useGetProfileQuery } from "@/generated-types";

export function SuggestTopicDialog() {
  const [isSuggestDialogOpen, setIsSuggestDialogOpen] = useState(false);

  return (
    <Dialog open={isSuggestDialogOpen} onOpenChange={setIsSuggestDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="link" className="">
          Not finding what you want? Suggest it
          <MessageCirclePlus className="ml-2 size-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Suggest a New Topic</DialogTitle>
        </DialogHeader>
        <SuggestTopicForm onClose={() => setIsSuggestDialogOpen(false)} />
      </DialogContent>
    </Dialog>
  );
}

const FormSchema = z.object({
  title: z.string().min(4),
  description: z.string().min(8),
});

type FormValueType = z.infer<typeof FormSchema>;

function SuggestTopicForm({ onClose }: { onClose: () => void }) {
  const { data: profileData } = useGetProfileQuery();
  const [createTagRequest, { loading }] = useCreateTagRequestMutation();

  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: "",
      description: "",
    },
    mode: "onBlur",
  });

  const {
    formState: { isValid },
  } = form;

  async function doCreateTagRequest(data: FormValueType) {
    await createTagRequest({
      variables: {
        userId: profileData?.me?.id!,
        ...data,
      },
    });
    toast.success("Suggestion received. Thank you!");
    onClose();
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          form.handleSubmit(doCreateTagRequest)(e);
          // prevent the submit event causing the enclusing form to submit
          e.stopPropagation();
        }}
        className="space-y-4"
      >
        {/* title */}
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Topic Title *</FormLabel>
              <FormControl>
                <Input {...field} required />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* description */}
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description *</FormLabel>
              <FormControl>
                <Textarea {...field} required />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormActions disabled={!isValid} loading={loading} nextLabel="Submit Suggestion" />
      </form>
    </Form>
  );
}

import React from "react";
import { PageLayout } from "@/components/page-layout";

export default function NotFoundPage() {
  return (
    <PageLayout>
      <main className="container mx-auto">
        <h1 className="content__title page-title">Not Found</h1>
      </main>
    </PageLayout>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Owner, useUpdateOwnerMutation } from "@/generated-types";

type OwnerNameFormProps = {
  owner: Pick<Owner, "id" | "name"> | undefined;
  onClose?: () => void;
};

export function OwnerNameMiniForm({ owner, onClose }: OwnerNameFormProps) {
  const [updateOwner, { data: updateOwnerResult, loading: updatingOwner }] =
    useUpdateOwnerMutation();

  const handleSave = async (data: z.infer<typeof FormSchema>) => {
    const { name } = data;
    await updateOwner({
      variables: {
        id: owner?.id!, // ID must exist
        data: {
          name,
          nameLC: name.toLowerCase(),
        },
      },
    });

    onClose && onClose();
  };

  const FormSchema = z.object({
    name: z.string().min(2, {
      message: "Please enter the valid business name",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: owner?.name || "",
    },
    mode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} className="space-y-2">
        {/* first name */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Business Name *</FormLabel> */}
              <FormControl>
                <Input {...field} autoCapitalize="words" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={updatingOwner}
          onBack={onClose}
          nextLabel="Save"
          backLabel="Cancel"
        />
      </form>
    </Form>
  );
}

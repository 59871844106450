// a quick and easy popup message
// can be used to display a confirmation after an action
// interface shared with admin-portal, implementation is not

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { forwardRef, Ref, useImperativeHandle, useState } from "react";

export type QuickConfirmRef = {
  alert(title: string, message: string): void;
  confirm(title: string, message: string): Promise<boolean>;
};

type ConfirmProps = {};

export const QuickConfirm = forwardRef((props: ConfirmProps, ref: Ref<QuickConfirmRef>) => {
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [resolver, setResolver] = useState<(value: boolean) => void>();

  const [isOpen, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    alert(t: string, m: string) {
      setTitle(t);
      setMessage(m);
      setOpen(true);
    },
    confirm(t: string, m: string) {
      setTitle(t);
      setMessage(m);
      setOpen(true);
      return new Promise((resolveFn) => {
        setResolver(() => resolveFn);
      });
    },
  }));

  const handleCancel = () => {
    resolver && resolver(false);
    setOpen(false);
    setResolver(undefined);
  };

  const handleContinue = () => {
    resolver && resolver(true);
    setOpen(false);
    setResolver(undefined);
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{message}</AlertDialogDescription>
        </AlertDialogHeader>
        {resolver ? (
          <AlertDialogFooter className="mt-2 gap-2">
            <AlertDialogCancel onClick={handleCancel}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleContinue}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        ) : (
          <AlertDialogFooter className="mt-2 gap-2">
            <AlertDialogCancel onClick={handleCancel}>Close</AlertDialogCancel>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
});

QuickConfirm.displayName = "QuickConfirm";

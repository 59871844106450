import React, { useState } from "react";

import { Spinner } from "@/components/page-loader";
import { SquarePenIcon } from "lucide-react";
import ReactImageCropperDropzone from "react-image-cropper-dropzone";
import "react-image-cropper-dropzone/dist/index.css";

type EditPictureProps = {
  onSave: (newPicture: string) => void;
  className?: string;
};

const EditPicture: React.FC<EditPictureProps> = ({ onSave, className }) => {
  const [loading, setLoading] = useState(false);

  const afterCrop = (base64: string) => {
    console.log("image size: ", base64.length);
    setLoading(false);
    onSave(base64);
    // download image
    // const link = document.createElement("a");
    // link.href = base64;
    // link.download = "image.png";
    // link.click();
  };

  const handleEdit = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);
    // reset the spinner automatically, in case the user cancels photo selection
  };

  return (
    <div className={className}>
      <ReactImageCropperDropzone
        cropper={{ initialAspectRatio: 1, aspectRatio: 1 }}
        output={{ maxHeight: 400, maxWidth: 400, format: "image/jpeg", compression: 0.8 }}
        afterCrop={afterCrop}
        styles={{
          overlay: {
            zIndex: 999,
          },
          modal: {
            width: "80vh",
            height: "80vh",
            maxWidth: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
          cropper: {
            height: "calc(100% - 5rem)",
            width: "100%",
          },
          saveButton: {
            borderRadius: "6px",
            backgroundColor: "#290099",
            marginTop: "0.5rem",
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: 600,
            color: "white",
            height: "2.5rem",
            width: "6rem",
            alignSelf: "center",
          },
        }}
      >
        <div role="button">
          {loading ? (
            <Spinner />
          ) : (
            <SquarePenIcon
              className="size-4 text-primary"
              aria-hidden="true"
              onClick={handleEdit}
            />
          )}
        </div>
      </ReactImageCropperDropzone>
    </div>
  );
};

export default EditPicture;

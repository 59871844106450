import { PageLoader } from "@/components/page-loader";
import { useGetProfileQuery } from "@/generated-types";
import { useAuth0 } from "@auth0/auth0-react";
import { AddContentDialog } from "../content-page/add-content-dialog";
import { ContentLibrary } from "../content-page/content-library";

export default function AddChannelPage() {
  const { user } = useAuth0();

  const {
    data: profileData,
    loading: loadingProfile,
    refetch: refectchProfile,
  } = useGetProfileQuery();

  if (!user) {
    return null;
  }

  const emptyView = (
    <>
      <h1 id="page-title" className="text-2xl font-bold tracking-tight text-gray-900">
        Content Library
      </h1>
      <div className="mt-6 flex gap-20">
        <div className="hidden w-2/5 md:block">
          <div className="flex flex-col p-4">
            <div className="grow">
              <h1 className="mb-4 text-center text-3xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-5xl">
                <span className="bg-gradient-to-r from-sky-400 to-emerald-600 bg-clip-text text-transparent">
                  Add Your Content
                </span>{" "}
                to Credtent
              </h1>
              <p className="text-center text-lg font-light text-gray-500 dark:text-gray-400 lg:text-xl">
                Add your Content Channels or Individual Works for AI Protection or Licensing.
              </p>
              <div className="w-full lg:ml-[20%] lg:w-4/5 ">
                <img src="/assets/upload.jpg" className="mt-5 object-contain py-8" alt="upload" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1">
          <div className="mb-8 mt-4 rounded-lg bg-gray-100 p-4 dark:bg-slate-900">
            <h2 className="text-lg leading-8 text-gray-600">
              Add New Content Channel or Individual Work:
            </h2>
            <AddContentDialog />
          </div>
          <div className="w-full text-lg font-light text-gray-500 lg:w-3/4">
            <p className="">
              Add all of your creative works and channels onto Credtent. Your license allows you to
              register all content you have created for one annual price.
            </p>
            <ul className="ml-8 list-disc">
              <li className="mt-4">
                Add your books, music albums, digital and tabletop games as individual works.
              </li>
              <li className="mt-4">
                Add your social media or video accounts, Substack, website full of your photos or
                artwork as channels.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <main className="container mx-auto">
      <PageLoader overlay loading={loadingProfile} />
      <ContentLibrary profile={profileData?.me} showHeader emptyView={emptyView} />
    </main>
  );
}

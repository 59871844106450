import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useApolloClient } from "@apollo/client";

export const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();
  const client = useApolloClient();
  const handleLogout = async () => {
    localStorage.clear();
    await client.clearStore();
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={handleLogout} className="button block">
      Log out
    </a>
  );
};

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { formatLegalName } from "@/utils/formatter";
import ellipsize from "ellipsize";
import { CheckIcon, MinusIcon, PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";

import { Spinner } from "@/components/page-loader";
import { Button } from "@/components/ui/button";
import { cn } from "@/utils";
import { SelectStakeholderSheet } from "./select-stakeholder-sheet";
import { type Stakeholder, type TypeOfStakeholder } from "./types";

type StackholderProps = {
  item: Stakeholder;
  you?: boolean;
  onSelect?: (item: Stakeholder) => void | Promise<void>;
  onDelete?: (item: Stakeholder) => void;
  className?: string;
  loading?: boolean;
};

export function StakeholderItem({
  item,
  you,
  onSelect,
  onDelete,
  className,
  loading,
}: StackholderProps) {
  let avatarUrl: string | undefined,
    avatarFallback: string | undefined,
    title: string = "(untitled)",
    subtitle: string | undefined;
  if ("firstName" in item) {
    const { firstName, lastName, profilePicture, bio } = item;
    title = formatLegalName(item, /*initial*/ true);
    avatarUrl = profilePicture || undefined;
    avatarFallback = `${firstName?.charAt(0) || ""}${lastName?.charAt(0) || ""}`;
    subtitle = bio || undefined;
  } else if ("name" in item) {
    const { name, profilePicture } = item;
    avatarUrl = profilePicture || undefined;
    avatarFallback = name?.charAt(0) || "";
    title = name || "(anonymous rights owner)";
  }

  return (
    <div className={cn("flex items-center space-x-4", className)}>
      <Avatar>
        <AvatarImage src={avatarUrl} />
        <AvatarFallback>{avatarFallback}</AvatarFallback>
      </Avatar>
      <div className="flex-1 space-y-1">
        <div className="text-sm font-medium leading-none">
          {title}
          {you && (
            <Badge variant="default" className="ml-4">
              You
            </Badge>
          )}
        </div>
        {!item.account?.id && (
          <Badge variant="secondary" className="mr-2 text-xs">
            invited
          </Badge>
        )}

        {subtitle && (
          <p className="text-sm leading-tight text-muted-foreground">{ellipsize(subtitle, 100)}</p>
        )}
      </div>
      {onSelect && (
        <Button
          size="icon"
          variant="secondary"
          className="rounded-full"
          onClick={() => onSelect(item)}
        >
          <CheckIcon className="size-4" />
        </Button>
      )}
      {onDelete && !you && (
        <Button
          size="icon"
          variant="secondary"
          className="rounded-full"
          onClick={() => onDelete(item)}
        >
          {loading ? <Spinner /> : <MinusIcon className="size-4" />}
        </Button>
      )}
    </div>
  );
}

type StakeholderListProps = {
  type: TypeOfStakeholder;
  userCreatorId?: string; // if the user has a Creator profile, the ID is passed in
  userOwnerId?: string; // if the user has a Rights Owner profile, the ID is passed in
  value: Stakeholder[];
  onAdd: (value: Stakeholder) => void | Promise<void>;
  onDelete: (value: Stakeholder) => void | Promise<void>;
  loading?: boolean;
  className?: string;
};

export function StakeholderList({
  type: typeOfStakeholder,
  value,
  userCreatorId,
  userOwnerId,
  onAdd,
  onDelete,
  loading,
  className,
}: StakeholderListProps) {
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState<string>();

  useEffect(() => {
    if (!loading) {
      setAdding(false);
      setDeleting(undefined);
    }
  }, [loading]);

  function handleAdd(v: Stakeholder) {
    setAdding(true);
    onAdd(v);
  }

  function handleDelete(v: Stakeholder) {
    setDeleting(v.id);
    onDelete(v);
  }

  return (
    <div className={cn("space-y-4 divide-y", className)}>
      {value.map((item) => (
        <StakeholderItem
          key={item.id}
          item={item}
          you={item.id === userCreatorId || item.id === userOwnerId}
          onDelete={handleDelete}
          className="pt-4"
          loading={deleting === item.id}
        />
      ))}
      {/* render add button */}
      <SelectStakeholderSheet
        type={typeOfStakeholder}
        trigger={
          <Button
            variant="secondary"
            size="sm"
            className="mt-4 rounded-full border-none capitalize"
          >
            {adding ? <Spinner className="mr-2 size-4" /> : <PlusIcon className="mr-2 size-4" />}
            Add {typeOfStakeholder}
          </Button>
        }
        onSelect={handleAdd}
      />
    </div>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import first from "lodash/first";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useBlocker, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import {
  CertificationType,
  ContentBadgeType,
  GetChannelByIdQuery,
  GetProfileQuery,
  GetWorkByIdQuery,
  useCreateChannelMutation,
  useCreateWorkMutation,
  useDeleteChannelMutation,
  useDeleteWorkMutation,
  useUpdateChannelMutation,
  useUpdateWorkMutation,
} from "@/generated-types";
import { QuickConfirm, QuickConfirmRef } from "@/utils/confirm";
import { resolveConnectForUpdate, resolveConnection, useTypeTags } from "@/utils/tag-helpers";
import { TagsEditor, useTagEditor } from "../ProfilePage/tags-editor";
import { CategoryEditor } from "./category-editor";
import { CONTENT_BADGES, SelectBadge } from "./select-badge";
import { UrlAnalyzer } from "./url-analyzer";

type MetadataFormProps = {
  profile: GetProfileQuery["me"];
  channel?: GetChannelByIdQuery["channels"][number];
  work?: GetWorkByIdQuery["works"][number];
  creating?: boolean;
  creatingSimilar?: boolean;
  onComplete?: (type: "channel" | "work", id: string) => void;
};

export function MetadataForm({
  creating,
  creatingSimilar,
  profile,
  channel: existingChannel,
  work: existingWork,
  onComplete,
}: MetadataFormProps) {
  const navigate = useNavigate();
  const [createChannel, { loading: creatingChannel }] = useCreateChannelMutation({
    refetchQueries: ["GetChannelsByCreator", "GetChannelsByOwner"],
  });
  const [createWork, { loading: creatingWork }] = useCreateWorkMutation({
    refetchQueries: ["GetWorksByCreator", "GetWorksByOwner"],
  });
  const [updateChannel, { loading: updatingChannel }] = useUpdateChannelMutation();
  const [updateWork, { loading: updatingWork }] = useUpdateWorkMutation();
  const [deleteChannel, { loading: deletingChannel }] = useDeleteChannelMutation({
    refetchQueries: ["GetChannelsByCreator", "GetChannelsByOwner"],
  });
  const [deleteWork, { loading: deletingWork }] = useDeleteWorkMutation({
    refetchQueries: ["GetWorksByCreator", "GetWorksByOwner"],
  });

  const { typeTags, typeTagsMap } = useTypeTags();
  const { resolveTags } = useTagEditor();
  const confirmRef = useRef<QuickConfirmRef>(null);

  const FormSchema = z
    .object({
      typeOfContent: z.enum(["channel", "work"]),
      hasCanonicalUrl: z.boolean(),
      canonicalUrl: z.string().url("Invalid web address").or(z.literal("")),
      name: z.string().min(4),
      description: z.string().min(8),
      keywords: z.array(z.string()),
      media: z
        .array(
          z.object({
            id: z.string(),
          })
        )
        .optional(),
      typeTag: z.string().optional(),
      secondaryTypeTag: z.string().optional(),
      tertiaryTypeTag: z.string().optional(),
      categories: z
        .array(
          z.object({
            id: z.string(),
            title: z.string().optional().nullable(),
            // optional() and nullable() are for displaying existing channel/work
            icon: z.string().optional().nullable(),
          })
        )
        .min(1, { message: "Select at least one category" }),
      contentBadges: z.array(z.string()).min(1, { message: "Select at least one badge" }),
    })
    .refine(
      // if hasCanonicalURl is true, then the user must enter a non empty url
      (data) => {
        if (data.hasCanonicalUrl && !data.canonicalUrl) return false;
        else return true;
      },
      { message: "Web address is required", path: ["canonicalUrl"] }
    )
    .superRefine((data, ctx) => {
      // try enforce the rule that `type` is required
      if (!data.typeTag) {
        // if type is not selected
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please select a type for your content",
          path: ["typeTag"],
        });
      } else if (!data.secondaryTypeTag) {
        // if secondary type is not selected, check if it is needed
        // the secondary type is required only if the *currently selected* type has children
        if (typeTagsMap[data.typeTag]?.hasChildren)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a type for your content",
            path: ["secondaryTypeTag"],
          });
      } else if (!data.tertiaryTypeTag) {
        // if tertiary type is not selected, check if it is needed
        // the tertiary type is only required if the *currently selected* secondary type has children
        if (typeTagsMap[data.secondaryTypeTag]?.hasChildren)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a type for your content",
            path: ["tertiaryTypeTag"],
          });
      }
    });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      typeOfContent: "channel",
      hasCanonicalUrl: true,
      canonicalUrl: "",
      name: "",
      description: "",
      keywords: [],
      media: [],
      typeTag: "",
      secondaryTypeTag: "",
      tertiaryTypeTag: "",
      categories: [],
      contentBadges: [],
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, isDirty, errors },
    setValue,
    reset,
    watch,
  } = form;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname && !creatingSimilar
  );

  const {
    typeOfContent,
    hasCanonicalUrl,
    typeTag,
    secondaryTypeTag,
    tertiaryTypeTag,
    canonicalUrl,
  } = watch();

  useEffect(() => {
    // when the user selects a new typeTag, if the secondaryTypeTag is not a child of the new typeTag, set it to undefined
    if (secondaryTypeTag && typeTagsMap[secondaryTypeTag]?.parentId !== typeTag) {
      setValue("secondaryTypeTag", undefined, { shouldValidate: true });
    }
    if (tertiaryTypeTag && typeTagsMap[tertiaryTypeTag]?.parentId !== secondaryTypeTag) {
      setValue("tertiaryTypeTag", undefined, { shouldValidate: true });
    }

    // when the user selects "no canonial url", clear the url field
    if (!hasCanonicalUrl) {
      setValue("canonicalUrl", "", { shouldValidate: true });
    }
  }, [typeTag, secondaryTypeTag, tertiaryTypeTag, hasCanonicalUrl, setValue, typeTagsMap]);

  useEffect(() => {
    // the form is loaded with an existing Channel, populate the form with data from the entity
    const types = existingChannel?.channelTypes || existingWork?.workTypes || [];
    const typeTagValues: Partial<z.infer<typeof FormSchema>> = {
      typeTag: types.find((item) => item.tier === 0)?.id,
      secondaryTypeTag: types.find((item) => item.tier === 1)?.id,
      tertiaryTypeTag: types.find((item) => item.tier === 2)?.id,
    };

    const keywords = (existingChannel?.tags || existingWork?.tags || [])
      .filter((tag) => !tag.blocked && tag.title)
      .map((tag) => tag.title || "");

    const resetForSimilar = creatingSimilar
      ? { canonicalUrl: "", name: "", description: "", media: [] }
      : undefined;

    if (existingChannel) {
      reset({
        typeOfContent: "channel",
        hasCanonicalUrl: true,
        canonicalUrl: existingChannel.channelUrl || "",
        name: existingChannel.channelName || undefined,
        description: existingChannel.description || undefined,
        categories: existingChannel.categories,
        contentBadges: existingChannel.contentBadges.map((item) => item.id),
        media: existingChannel.media,
        keywords,
        ...typeTagValues,
        ...resetForSimilar,
      });
    } else if (existingWork) {
      reset({
        typeOfContent: "work",
        hasCanonicalUrl: Boolean(existingWork.workUrl),
        canonicalUrl: existingWork.workUrl || "",
        name: existingWork.title || undefined,
        description: existingWork.description || undefined,
        categories: existingWork.categories,
        contentBadges: existingWork.contentBadges.map((item) => item.id),
        media: existingWork.media,
        keywords,
        ...typeTagValues,
        ...resetForSimilar,
      });
    }
  }, [existingChannel, existingWork, reset, creatingSimilar]);

  useEffect(() => {
    // if cannonicalUrl doesn't start with http:// or https://, append https://
    if (
      canonicalUrl &&
      !canonicalUrl.startsWith("http://") &&
      !canonicalUrl.startsWith("https://") &&
      !canonicalUrl.match(/^h?t?t?p?s?:?\/?\/?$/) // the user is trying to type h t t p s : / /, don't interrupt, lol
    ) {
      setValue("canonicalUrl", "https://" + canonicalUrl, { shouldValidate: true });
    }
  }, [canonicalUrl, setValue]);

  // current user's Creator ID or Rights Owner ID
  // One of them should have value
  const userCreatorId = first(profile?.account?.creators)?.id;
  const userOwnerId = profile?.account?.owner?.id;

  const showHasUrlField = typeOfContent === "work";
  const showUrlField = typeOfContent === "channel" || hasCanonicalUrl;

  const tier0Types = typeTags.filter((tag) => tag.tier === 0);
  const tier1Types = typeTags.filter((tag) => tag.tier === 1 && tag.parentId === typeTag);
  const tier2Types = typeTags.filter((tag) => tag.tier === 2 && tag.parentId === secondaryTypeTag);

  const typeTagTitle = typeTag && typeTagsMap[typeTag]?.title;
  const secondaryTypeTagTitle = secondaryTypeTag && typeTagsMap[secondaryTypeTag]?.title;

  const showSecondaryTypeField = tier1Types.length > 0;
  const showTertiaryTypeField = tier2Types.length > 0;

  async function handleCreateContent(data: z.infer<typeof FormSchema>) {
    const {
      typeOfContent,
      name,
      description,
      canonicalUrl,
      typeTag,
      secondaryTypeTag,
      tertiaryTypeTag,
      categories,
      contentBadges,
      keywords,
      media,
    } = data;

    const typeTagIds: string[] = [typeTag!]; // typeTag can't be undefined if the form passed validation
    if (showSecondaryTypeField && secondaryTypeTag) typeTagIds.push(secondaryTypeTag);
    if (showTertiaryTypeField && tertiaryTypeTag) typeTagIds.push(tertiaryTypeTag);

    if (typeOfContent === "channel") {
      const result = await createChannel({
        variables: {
          input: {
            channelUrl: canonicalUrl,
            channelName: name,
            description,
            channelTypes: resolveConnection(typeTagIds),
            categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            media: resolveConnection(media || []),
            creators: resolveConnection([{ id: userCreatorId }]),
            owners: resolveConnection([{ id: userOwnerId }]),
            accounts: { connect: [{ where: { node: { id: profile?.account?.id! } } }] },
          },
        },
      });
      const newChannelId = first(result.data?.createChannels.channels)?.id;
      if (newChannelId) {
        toast.success("Channel added successfully.");
        onComplete && onComplete("channel", newChannelId);
      }
    } else {
      const result = await createWork({
        variables: {
          input: {
            workUrl: canonicalUrl,
            title: name,
            description,
            workTypes: resolveConnection(typeTagIds),
            categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            media: resolveConnection(media || []),
            creators: resolveConnection([{ id: userCreatorId }]),
            owners: resolveConnection([{ id: userOwnerId }]),
            accounts: { connect: [{ where: { node: { id: profile?.account?.id! } } }] },
          },
        },
      });
      const newWorkId = first(result.data?.createWorks.works)?.id;
      if (newWorkId) {
        toast.success("Work added successfully.");
        onComplete && onComplete("work", newWorkId);
      }
    }
  }

  async function handleCreateSimilarContent(data: z.infer<typeof FormSchema>) {
    const {
      typeOfContent,
      name,
      description,
      canonicalUrl,
      typeTag,
      secondaryTypeTag,
      tertiaryTypeTag,
      categories,
      contentBadges,
      keywords,
      media,
    } = data;

    const typeTagIds: string[] = [typeTag!]; // typeTag can't be undefined if the form passed validation
    if (showSecondaryTypeField && secondaryTypeTag) typeTagIds.push(secondaryTypeTag);
    if (showTertiaryTypeField && tertiaryTypeTag) typeTagIds.push(tertiaryTypeTag);

    if (typeOfContent === "channel") {
      const result = await createChannel({
        variables: {
          input: {
            channelUrl: canonicalUrl,
            channelName: name,
            description,
            channelTypes: resolveConnection(typeTagIds),
            categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            media: resolveConnection(media || []),
            // copy the creators and owners from the template, which could be a channel or a work
            creators: resolveConnection(existingChannel?.creators || existingWork?.creators || []),
            owners: resolveConnection(existingChannel?.owners || existingWork?.owners || []),
            accounts: resolveConnection(
              existingChannel?.accounts || existingWork?.accounts || [{ id: profile?.account?.id! }]
            ),
          },
        },
      });
      const newChannelId = first(result.data?.createChannels.channels)?.id;
      if (newChannelId) {
        toast.success("Channel added successfully.");
        onComplete && onComplete("channel", newChannelId);
      }
    } else {
      const result = await createWork({
        variables: {
          input: {
            workUrl: canonicalUrl,
            title: name,
            description,
            workTypes: resolveConnection(typeTagIds),
            categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            media: resolveConnection(media || []),
            // copy the creators and owners from the template, which could be a channel or a work
            creators: resolveConnection(existingChannel?.creators || existingWork?.creators || []),
            owners: resolveConnection(existingChannel?.owners || existingWork?.owners || []),
            accounts: resolveConnection(
              existingChannel?.accounts || existingWork?.accounts || [{ id: profile?.account?.id! }]
            ),
          },
        },
      });
      const newWorkId = first(result.data?.createWorks.works)?.id;
      if (newWorkId) {
        toast.success("Work added successfully.");
        onComplete && onComplete("work", newWorkId);
      }
    }
  }

  async function handleSaveContent(data: z.infer<typeof FormSchema>) {
    const {
      name,
      description,
      canonicalUrl,
      typeTag,
      secondaryTypeTag,
      tertiaryTypeTag,
      categories,
      contentBadges,
      keywords,
      media,
    } = data;

    // these are the type IDs the user wants to connect to
    const typeTagIds: string[] = [typeTag!]; // typeTag can't be undefined if the form passed validation
    if (showSecondaryTypeField && secondaryTypeTag) typeTagIds.push(secondaryTypeTag);
    if (showTertiaryTypeField && tertiaryTypeTag) typeTagIds.push(tertiaryTypeTag);

    if (existingChannel) {
      await updateChannel({
        variables: {
          where: { id: existingChannel.id },
          update: {
            channelUrl: canonicalUrl,
            channelName: name,
            description,
            channelTypes: resolveConnectForUpdate(typeTagIds, existingChannel.channelTypes),
            categories: resolveConnectForUpdate(categories, existingChannel.categories),
            contentBadges: resolveConnectForUpdate(contentBadges, existingChannel.contentBadges),
            media: resolveConnectForUpdate(media || [], /*existingChannel.media*/ []),
            // media isn't the main focus of this tab. The only time media ever need to be saved
            // is when the user analyzes a URL. There is no chance we ever want to disconnect or delete
            // any media
            tags: [resolveTags(keywords, existingChannel.tags)],
            // note we are NOT changing creators and owners
          },
        },
      });
      toast.success("Channel saved successfully.");
    } else if (existingWork) {
      await updateWork({
        variables: {
          where: { id: existingWork.id },
          update: {
            workUrl: canonicalUrl,
            title: name,
            description,
            workTypes: resolveConnectForUpdate(typeTagIds, existingWork.workTypes),
            categories: resolveConnectForUpdate(categories, existingWork.categories),
            contentBadges: resolveConnectForUpdate(contentBadges, existingWork.contentBadges),
            media: resolveConnectForUpdate(media || [], /*existingWork.media*/ []),
            tags: [resolveTags(keywords, existingWork.tags)],
            // note we are NOT changing creators and owners
          },
        },
      });
      toast.success("Work saved successfully.");
    }
  }

  async function handleDeleteContent() {
    const answer = await confirmRef.current?.confirm(
      `Deleting this ${typeOfContent}?`,
      `This operation can't be undone. Please confirm.`
    );
    if (answer) {
      if (typeOfContent === "channel" && existingChannel)
        await deleteChannel({ variables: { channelId: existingChannel.id } });
      else if (typeOfContent === "work" && existingWork)
        await deleteWork({ variables: { workId: existingWork.id } });
      navigate("/content");
    }
  }

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    if (creating) handleCreateContent(data);
    else if (creatingSimilar) handleCreateSimilarContent(data);
    else handleSaveContent(data);
  }

  // console.log("content metadata form", {
  //   errors,
  //   values: watch(),
  //   isValid,
  // });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {/* type of content */}
        <FormField
          control={form.control}
          name="typeOfContent"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>This content is a:</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  value={field.value}
                  className="flex flex-col space-y-1"
                  disabled={!creating && !creatingSimilar}
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="channel" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      <span className="font-semibold">Channel</span> (e.g. blog, podcast, or any
                      other medium which you contribute new material into indefinitely)
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="work" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      <span className="font-semibold">Work</span> (e.g. a book, a painting, a film,
                      or any other format that you can "complete")
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* has url */}
        {showHasUrlField && (
          <FormField
            control={form.control}
            name="hasCanonicalUrl"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>This work has a canonical website</FormLabel>
                </div>
              </FormItem>
            )}
          />
        )}

        {/* url */}
        {showUrlField && (
          <FormField
            control={form.control}
            name="canonicalUrl"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Web address of this {typeOfContent}?</FormLabel>
                <FormControl>
                  <div className="flex w-full items-center space-x-2">
                    <Input {...field} placeholder="https:// or http://" autoComplete="no" />
                    <UrlAnalyzer
                      url={canonicalUrl}
                      typeOfContent={typeOfContent}
                      editing={!creating && !creatingSimilar}
                      onUse={(meta) => {
                        if (meta.name !== "") setValue("name", meta.name, { shouldDirty: true });
                        if (meta.description !== "")
                          setValue("description", meta.description, { shouldDirty: true });
                        const newKeywords = meta.keywords
                          .split(",")
                          .map((kw) => kw.trim().toLowerCase().replace(/\s+/g, "_"))
                          .filter((kw) => kw.length > 0);
                        if (newKeywords.length > 0)
                          setValue("keywords", newKeywords, { shouldDirty: true });
                        if (meta.media.length > 0) {
                          setValue("media", meta.media, { shouldDirty: true });
                        }
                      }}
                    />
                  </div>
                </FormControl>
                <FormDescription>
                  Enter the canonical web address of your {typeOfContent}, then press Analyze, we’ll
                  try to fill in the rest of the information for you.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* name */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* description */}
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description *</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* keywords */}
        <FormField
          control={form.control}
          name="keywords"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Keywords</FormLabel>
              <FormControl>
                <TagsEditor
                  value={field.value}
                  onChange={field.onChange}
                  className="flex bg-background ring-offset-background focus-visible:outline-none [&.tagify--focus]:ring-2 [&.tagify--focus]:ring-ring [&.tagify--focus]:ring-offset-2"
                  // trying to mimic classes used by a standard textarea
                />
              </FormControl>
              <FormDescription>
                Using only relevant keywords will make your {typeOfContent} easier to discover and
                license. Not necessary if you choose to mark this {typeOfContent} “Do Not Train”.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* type of content */}
        <FormField
          control={form.control}
          name="typeTag"
          render={({ field }) => (
            <FormItem>
              <FormLabel>What type of content? *</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Please select a type" />
                  </SelectTrigger>
                  <SelectContent>
                    {tier0Types.map((item) => (
                      <SelectItem key={item.id} value={item.id}>
                        {item.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* secondary type */}
        {showSecondaryTypeField && (
          <FormField
            control={form.control}
            name="secondaryTypeTag"
            render={({ field }) => (
              <FormItem>
                <FormLabel>What type of {typeTagTitle}? *</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Please select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {tier1Types.map((item) => (
                        <SelectItem key={item.id} value={item.id}>
                          {item.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* tertiary type */}
        {showTertiaryTypeField && (
          <FormField
            control={form.control}
            name="tertiaryTypeTag"
            render={({ field }) => (
              <FormItem>
                <FormLabel>What type of {secondaryTypeTagTitle}? *</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Please select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {tier2Types.map((item) => (
                        <SelectItem key={item.id} value={item.id}>
                          {item.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* category tags */}
        <FormField
          control={form.control}
          name="categories"
          render={({ field }) => (
            <FormItem>
              <FormLabel>What topic does this {typeOfContent} cover? *</FormLabel>
              <FormControl>
                <CategoryEditor
                  typeOfContent={typeOfContent}
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* content badges */}
        <FormField
          control={form.control}
          name="contentBadges"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                How do you best describe the origin(s) of this {typeOfContent}? *
              </FormLabel>
              <FormControl>
                <SelectBadge
                  options={CONTENT_BADGES.filter(
                    (item) =>
                      item.certification === CertificationType.Uncertified &&
                      item.type === ContentBadgeType.Origin
                  )}
                  multi
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={creatingChannel || creatingWork || updatingChannel || updatingWork}
          deleting={deletingChannel || deletingWork}
          nextLabel={creating || creatingSimilar ? "Add" : "Save"}
          onDelete={creating || creatingSimilar ? undefined : handleDeleteContent}
          className="pt-4"
        />
      </form>
      <QuickConfirm ref={confirmRef} />
      <AlertDialog open={blocker.state === "blocked"}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>You have unsaved data</AlertDialogTitle>
            <AlertDialogDescription>Are you sure you want to leave?</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-2 gap-2">
            <AlertDialogCancel onClick={() => blocker?.reset && blocker.reset()}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => blocker?.proceed && blocker.proceed()}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Form>
  );
}

import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TagsEditor } from "../ProfilePage/tags-editor";
import { Undefinedable } from "./types";

const FormSchema = z.object({
  typeOfContent: z.enum(["channel", "work"]),
  name: z.string().min(4),
  description: z.string().min(8),
  keywords: z.array(z.string()),
});

export type FormValueType = z.infer<typeof FormSchema>;

type AddContentStepFormProps = {
  value: Undefinedable<FormValueType>;
  onNext: (value: FormValueType) => void;
  onBack?: () => void;
};

export function AddContentFormStep2({ value, onBack, onNext }: AddContentStepFormProps) {
  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      typeOfContent: "channel",
      name: "",
      description: "",
      keywords: [],
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, errors },
    reset,
    watch,
  } = form;

  const { typeOfContent } = watch();

  useEffect(() => {
    reset(value);
  }, [value, reset]);

  function onSubmit(data: FormValueType) {
    onNext(data);
  }

  // console.log("add content step 2", {
  //   errors,
  //   values: watch(),
  //   isValid,
  // });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-8">
        {/* name */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* description */}
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description *</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* keywords */}
        <FormField
          control={form.control}
          name="keywords"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Keywords</FormLabel>
              <FormControl>
                <TagsEditor
                  value={field.value}
                  onChange={field.onChange}
                  className="flex bg-background ring-offset-background focus-visible:outline-none [&.tagify--focus]:ring-2 [&.tagify--focus]:ring-ring [&.tagify--focus]:ring-offset-2"
                  // trying to mimic classes used by a standard textarea
                />
              </FormControl>
              <FormDescription>
                Using only relevant keywords will make your {typeOfContent} easier to discover and
                license. Not necessary if you choose to mark this {typeOfContent} “Do Not Train”.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormActions disabled={!isValid} onBack={onBack} className="" />
      </form>
    </Form>
  );
}

// (should be) shared with admin-portal

import { parse, isValid, format } from "date-fns";
import { Creator, CreatorContact, GenderTypes } from "@/generated-types";
import { GENDER_VALUES } from "@/utils/options";

export function formatLegalName(
  creator: Pick<Creator, "firstName" | "lastName" | "middleName"> | undefined,
  initial: boolean = false
) {
  if (!creator) return "";
  const names: string[] = [];
  const { firstName, middleName, lastName } = creator;
  if (firstName && firstName.length > 0) names.push(firstName);
  if (middleName && middleName.length > 0)
    names.push(initial ? middleName.charAt(0) + "." : middleName);
  if (lastName && lastName.length > 0) names.push(lastName);
  return names.join(" ");
}

export function formatAddress(
  contact:
    | Pick<
        CreatorContact,
        "addressLine1" | "addressLine2" | "city" | "stateProvince" | "postalCode" | "country"
      >
    | undefined
) {
  if (!contact) return undefined;
  return (
    <>
      <p>{contact.addressLine1}</p>
      <p>{contact.addressLine2}</p>
      <p>
        {contact.city}
        {", "}
        {contact.stateProvince} {contact.postalCode}
      </p>
      {contact.country ? <p>{contact.country}</p> : null}
    </>
  );
}

export function formatGender(creator: Pick<Creator, "gender" | "genderOther"> | undefined) {
  if (!creator) return "";
  const { gender, genderOther } = creator;
  if (gender === GenderTypes.Other) return genderOther;
  else if (gender) return GENDER_VALUES[gender];
  else return "";
}

export function safeParseDate(dateStr: string | undefined | null, format: string) {
  try {
    if (!dateStr) return undefined;
    const parsedDate = parse(dateStr, format, new Date());
    if (isValid(parsedDate)) {
      return parsedDate;
    } else {
      console.log("Parsed date is invalid", dateStr);
      return undefined;
    }
  } catch (error) {
    console.error("Error parsing date:", dateStr, error);
    return undefined; // or any fallback value you prefer
  }
}

// a safe and convenient way to convert date from database format to display format
export function parseAndFormatDate<T = string>(
  raw: string | undefined | null,
  originalFormat: string,
  targetFormat: string,
  defaultValue: T | string = ""
): T | string {
  if (!raw) return defaultValue;
  const parsed = safeParseDate(raw, originalFormat);
  if (parsed) return format(parsed, targetFormat);
  else return defaultValue;
}

export function makeMediaURL(media: { id: string; extension?: string | undefined | null }) {
  const { id, extension } = media;
  return process.env.REACT_APP_USER_MEDIA_PREFIX + "/" + id + (extension ? "." + extension : "");
}

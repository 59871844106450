import { SquarePenIcon, CheckIcon, XIcon, EyeIcon, EyeOffIcon } from "lucide-react";
import { FC, ReactElement, ReactNode, cloneElement, useEffect, useState } from "react";

import clsx from "clsx";

type ValueBoxProps = {
  label: string;
  remarks?: string;
  publicViewable?: boolean;
  value?: string | undefined | null;
  readonly?: boolean;
  editTrigger?: ReactNode;
  customEditor?: ReactElement;
  customActions?: boolean;
  onEdit?: () => void | Promise<void>;
  onSave?: (newValue: string | undefined) => string | undefined | Promise<string | undefined>;
  className?: string;
  children?: ReactNode;
};

const ValueBox: FC<ValueBoxProps> = ({
  label,
  remarks,
  publicViewable,
  value,
  readonly,
  editTrigger,
  customEditor,
  customActions,
  onEdit,
  onSave,
  children,
  className,
}) => {
  const [editing, setEditing] = useState(false);
  const [localValue, setLocalValue] = useState<string | undefined>(value || undefined);

  useEffect(() => {
    setLocalValue(value || undefined);
  }, [value, setLocalValue]);

  const renderEditingControls = () => {
    if (readonly) return null;
    if (editing) {
      if (customActions) return null;
      return (
        <>
          {/* cancel */}
          <div
            className="-my-1.5 cursor-pointer p-2 align-text-bottom"
            role="button"
            onClick={() => {
              setEditing(false);
              setLocalValue(value || undefined);
            }}
          >
            <XIcon className="size-4 text-primary" />
          </div>
          {/* ok */}
          <div
            className="-mx-2 -my-1.5 cursor-pointer p-2 align-text-bottom"
            role="button"
            onClick={async () => {
              if (onSave) {
                // the actual value saved might not be the same as the value we try to save, e.g. tags might get filtered, or transformed
                const newValue = await onSave(localValue);
                setLocalValue(newValue);
              }
              setEditing(false);
            }}
          >
            <CheckIcon className="size-4 text-primary" />
          </div>
        </>
      );
    } else {
      // not editing
      return (
        <div
          className="-my-1.5 cursor-pointer p-2 align-text-bottom text-primary"
          role="button"
          onClick={() => {
            setEditing(true);
            setLocalValue(value || "");
          }}
        >
          <SquarePenIcon className="size-4" />
        </div>
      );
    }
  };

  const renderValueOrEditor = () => {
    if (editing) {
      if (customEditor) {
        const cloned = cloneElement(customEditor, {
          onClose: () => setEditing(false),
          setLocalValue,
        });
        // Injecting a onClose handler to the `editor` component, typically a form
        // Note the editor is passed in from view-profile (or other host that uses this component),
        // which does not care about if the field is in editing mode.
        // We care about the end of editing mode, but we don't care about the business specifics.
        return cloned;
      } else
        return (
          <textarea
            autoFocus
            value={localValue}
            onChange={(event) => setLocalValue(event.currentTarget.value)}
            className="-mx-1 w-[calc(100%_+_8px)] border-none bg-[#f8f8f8] px-1 py-0 font-medium"
          />
        );
    } else {
      if (children) return children;
      else if (localValue || value)
        return (
          <p className="text-base font-medium text-slate-700 dark:text-white">
            {localValue || value}
          </p>
        );
      else return null;
    }
  };

  return (
    <div
      className={clsx(
        "dark:!bg-navy-700 relative flex flex-col rounded-md bg-white bg-clip-border ring-1 ring-gray-200",
        className || "col-span-2 row-span-1"
      )}
    >
      {/* header */}
      <div className="bg-muted px-4 pt-2">
        {publicViewable ? (
          <EyeIcon className="absolute right-2 top-2 size-4" />
        ) : (
          <EyeOffIcon className="absolute right-2 top-2 size-4" />
        )}

        <div className="mb-2 flex flex-row content-center">
          <p className="text-sm">{label}</p>
          {editTrigger ? editTrigger : renderEditingControls()}
        </div>
        {remarks ? <p className="-mt-2 mb-2 text-xs text-secondary-foreground">{remarks}</p> : null}
      </div>

      {/* body */}
      <div className="h-full overflow-hidden p-4">{renderValueOrEditor()}</div>
    </div>
  );
};

export default ValueBox;

import { useState } from "react";
import { Outlet } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Main } from "@/components/main";
import { NavBar } from "@/components/navigation";
import { SideBar } from "@/components/sidebar";

export default function ProtectedLayout() {
  return (
    <div className="h-screen overflow-auto">
      <NavBar logoLink="/overview" />
      <div id="content" className="top-16 flex size-full flex-row">
        <SideBar />
        <Main>
          <>
            <CookieConsent
              buttonText="Got it!"
              cookieName={"CredtentAppCookies"}
              buttonStyle={{ backgroundColor: "#290099", borderRadius: "4px", color: "white" }}
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
            <Outlet />
            {/* push the copyright footer to the bottom in case the main content is too short */}
            <div className="flex-1"></div>
            <div className="-mb-4 -mr-4 mt-4 text-right text-xs">
              &copy; Credtent, Inc. {new Date().getFullYear()}
            </div>
          </>
        </Main>
      </div>
    </div>
  );
}

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";

import { PageLoader } from "@/components/page-loader";
import { useGetProfileQuery } from "@/generated-types";
import { MetadataForm } from "./content-metadata-form";

export default function AddContentPage() {
  const navigate = useNavigate();
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();

  function handleComplete(type: "channel" | "work", id: string) {
    navigate(`/content/${type.charAt(0)}$${id}/creators-rights-owners`);
  }

  return (
    <div className="max-w-4xl">
      <PageLoader overlay loading={loadingProfile} />

      <h1 className="mb-8 text-3xl font-bold tracking-tight">Add Content</h1>

      <Card className="rounded-md bg-muted">
        <CardHeader>
          <CardTitle>Basic Content Info</CardTitle>
          <CardDescription>
            Enter information about your content here. If you decide to license your content for AI
            training, having accurate information will likely make it stand out in discovery, and
            improve its relevancy.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <MetadataForm profile={profileData?.me} creating onComplete={handleComplete} />
        </CardContent>
      </Card>
    </div>
  );
}

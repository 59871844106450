import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const SignupButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/onboarding/terms",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <button
      // className="rounded border border-purple-700 bg-purple-500 px-4 py-2 font-bold text-white hover:bg-purple-700"
      className="button secondary block"
      onClick={handleSignUp}
    >
      Sign Up
    </button>
  );
};

import { EyeIcon, EyeOffIcon } from "lucide-react";
import React from "react";

import { AddressForm } from "@/components/forms/address-mini-form";
import { OwnerNameMiniForm } from "@/components/forms/owner-name-mini-form";
import { OwnerTypeMiniForm } from "@/components/forms/owner-type-mini-form";
import { GetOwnerByAccountQuery, OwnerContact, PlanTypes, User } from "@/generated-types";
import { PlanList } from "@/pages/SubscribePage/onboarding-subscript-plans";
import { formatAddress } from "@/utils/formatter";
import { OWNER_TYPES_VALUES } from "@/utils/options";
import EditPicture from "./edit-picture";
import { TagsEditor } from "./tags-editor";
import ValueBox from "./value-box";

type OwnerItemType = GetOwnerByAccountQuery["owners"][number];

type ViewProfileProps = {
  loading: boolean;
  user: Pick<User, "userId" | "email" | "picture"> | undefined | null;
  plan: PlanTypes | undefined | null;
  owner: OwnerItemType | undefined;
  contact: Partial<OwnerContact> | undefined;
  onSavePicture: (pictureData: string) => void | Promise<void>;
  onSaveField: (
    field: string,
    value: string | undefined
  ) => string | undefined | Promise<string | undefined>;
  onSaveTags: (tags: string | undefined) => string | undefined | Promise<string | undefined>;
};

export const ViewOwnerProfile: React.FC<ViewProfileProps> = ({
  loading,
  user,
  plan,
  owner,
  contact,
  onSavePicture,
  onSaveField,
  onSaveTags,
}) => {
  // tags are filtered before display, just in case a tag was added before it is blocked by the site admin
  const tags: string[] =
    owner?.tags
      ?.filter((tagItem) => !tagItem.blocked && tagItem.title)
      .map((tagItem) => tagItem.title!) || [];

  return (
    <div className="my-8 flex flex-col ">
      <div className="flex flex-row items-center gap-2 text-sm font-light">
        <EyeIcon className="size-4 text-gray-400" />
        denotes information will be displayed publicly
      </div>
      <div className="flex flex-row items-center gap-2 text-sm font-light">
        <EyeOffIcon className="size-4 text-gray-400" />
        denotes information will NOT be displayed publicly
      </div>

      <div className="mt-4 grid w-full grid-flow-row-dense grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-8">
        <ValueBox
          label="Profile Picture"
          publicViewable
          className="col-span-2 row-span-4"
          editTrigger={
            <EditPicture onSave={onSavePicture} className="-my-1.5 cursor-pointer p-2" />
          }
        >
          <div className="flex size-full items-center justify-center p-4">
            {loading ? (
              <div className="size-[200px]" />
            ) : (
              <img
                src={owner?.profilePicture || user?.picture || "/assets/avatar-placeholder-400.png"}
                className="aspect-square size-[200px] max-w-full object-cover"
                alt="profile"
              />
            )}
          </div>
        </ValueBox>

        <ValueBox
          label="Business Name"
          value={owner?.name}
          publicViewable
          customEditor={<OwnerNameMiniForm owner={owner} />}
          customActions
          className="col-span-2 row-span-1"
        />

        <ValueBox label="Email" readonly value={user?.email} />

        <ValueBox
          label="Web Address"
          publicViewable
          value={owner?.url}
          onSave={(v) => onSaveField("url", v)}
        />

        <ValueBox
          label="Type of Business"
          publicViewable
          value={owner?.type ? OWNER_TYPES_VALUES[owner.type] : ""}
          customEditor={<OwnerTypeMiniForm owner={owner} />}
          customActions
        />

        <ValueBox
          label="Rights Owner Tags"
          publicViewable
          customEditor={<TagsEditor value={tags} placeholder="Tag1, tag2" />}
          value={tags.join(", ")}
          onSave={(v) => onSaveTags(v)}
          className="col-span-2 row-span-3"
        />

        <ValueBox
          label="Address"
          customEditor={<AddressForm entity="owner" entityId={owner?.id} address={contact} />}
          customActions // don't render the default X and V buttons, the AddressForm renders its own Cancel and Save
          className="col-span-2"
        >
          {formatAddress(contact)}
        </ValueBox>

        <ValueBox readonly label="Subscription Plan">
          <PlanList plan={plan} />
        </ValueBox>
      </div>
    </div>
  );
};

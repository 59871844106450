import { CheckIcon } from "lucide-react";
import { useState } from "react";

import { CategoryIcon } from "@/components/lists/category-icon";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import { ScrollArea } from "@/components/ui/scroll-area";
import { CategoryTag } from "@/generated-types";
import { SelectCategoryTag, useCategoryTags } from "@/utils/tag-helpers";
import { Search } from "lucide-react";
import { SuggestTopicDialog } from "./request-category-dialog";

type CatType = Pick<CategoryTag, "id" | "title" | "icon">;

type SelectCateorySheetProps = {
  typeOfContent: string;
  trigger: React.ReactElement;
  currentValue: CatType[];
  onDone: (newValue: CatType[]) => void;
};

export function SelectCateorySheet({
  typeOfContent,
  trigger,
  currentValue,
  onDone,
}: SelectCateorySheetProps) {
  const { initialize, toggleSelect, browseTags, searchTags, getValue } = useCategoryTags();
  const [mode, setMode] = useState<"browse" | "search">("browse");
  const [searchQuery, setSearchQuery] = useState("");
  const [rootTag, setRootTag] = useState<CatType | undefined>(undefined);
  const [searchResult, setSearchResult] = useState<{ title: string; items: SelectCategoryTag[] }[]>(
    []
  );

  const showBackButton = Boolean(rootTag);

  function handleBack() {
    setRootTag(undefined);
  }

  function handleOpenChange(open: boolean) {
    if (open) {
      initialize(currentValue);
      setRootTag(undefined);
      setMode("browse");
      setSearchQuery("");
    } else {
    }
  }

  function handleSearchInput(q: string) {
    setSearchQuery(q);
    if (q.trim().length > 2) {
      // minimum search term is 3-letters long
      setMode("search");
      setRootTag(undefined);
      setSearchResult(searchTags(q.trim()));
    } else {
      setMode("browse");
    }
  }

  function handleClearSearchInput() {
    setSearchQuery("");
    setMode("browse");
  }

  function handleItemClick(item: SelectCategoryTag) {
    if (item.children) setRootTag(item);
    else toggleSelect(item.id, !item.selected);
  }

  function handleClear() {
    initialize(currentValue);
    setSearchQuery("");
    setMode("browse");
  }

  function handleDone() {
    const selected = getValue();
    onDone(
      selected.map(({ id, title, icon }) => ({
        id,
        title,
        icon,
      }))
    );
  }

  return (
    <Sheet onOpenChange={handleOpenChange}>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <SheetContent className="flex w-full flex-col md:min-w-[75%] xl:min-w-[50%]">
        <SheetHeader>
          <SheetTitle>
            {rootTag
              ? `What subtopics does this ${typeOfContent} cover in ${rootTag.title}?`
              : `What topics does this ${typeOfContent} cover?`}
          </SheetTitle>
          <SheetDescription>Choose the most relevant category or categories.</SheetDescription>
        </SheetHeader>

        <div className="relative">
          <Search className="absolute left-2.5 top-3 size-4 text-muted-foreground" />
          <Input
            placeholder="Search topics and subtopics..."
            value={searchQuery}
            onChange={(e) => handleSearchInput(e.target.value)}
            className="pl-8"
          />
          <Button
            size="sm"
            variant="secondary"
            className="absolute right-1 top-1 h-auto p-2 text-xs"
            onClick={handleClearSearchInput}
          >
            CLEAR
          </Button>
        </div>
        <ScrollArea className="-mx-6 flex-1 px-6">
          {mode === "search" ? (
            <>
              {searchResult.map(({ title, items }) => (
                <CategoryGrid
                  key={title}
                  title={title}
                  items={items}
                  highlightText={searchQuery}
                  onClick={handleItemClick}
                />
              ))}
              {searchResult.length === 0 && (
                <div className="py-4 text-center">
                  <p>No matching topics found.</p>
                </div>
              )}
            </>
          ) : (
            <CategoryGrid items={browseTags(rootTag?.id)} onClick={handleItemClick} />
          )}
        </ScrollArea>

        <SheetFooter className="flex gap-2">
          <SuggestTopicDialog />
          <div className="hidden h-0 grow sm:block sm:grow" />
          {showBackButton ? (
            <Button variant="outline" onClick={handleBack}>
              Back
            </Button>
          ) : (
            <Button variant="outline" onClick={handleClear}>
              Revert Selection
            </Button>
          )}
          <SheetClose asChild>
            <Button onClick={handleDone}>Done</Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

type CategoryGridProps = {
  items: SelectCategoryTag[];
  title?: string;
  highlightText?: string;
  onClick: (item: SelectCategoryTag) => void;
};

function CategoryGrid({ title, items, highlightText, onClick }: CategoryGridProps) {
  const highlightMatch = (text: string, highlight: string = "") => {
    const trimmed = highlight.trim();
    if (!trimmed) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${trimmed})`, "gi");
    const parts = text.split(regex);
    return (
      <span>
        {parts.map((part, i) =>
          regex.test(part) ? (
            <mark key={i} className="bg-yellow-200">
              {part}
            </mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
      </span>
    );
  };

  const renderItem = (item: SelectCategoryTag) => (
    <div
      key={item.id}
      className="relative flex max-w-[200px] cursor-pointer flex-col items-center justify-start p-5 transition-colors duration-500 hover:bg-primary hover:text-white"
      onClick={() => onClick(item)}
    >
      {item.selected && (
        <CheckIcon className="absolute right-2 top-2 size-6 transition-opacity duration-500" />
      )}
      <CategoryIcon icon={item.icon} size={48} className="inline align-bottom" />
      <p className="mt-4 text-center text-sm leading-tight">
        {highlightMatch(item.title, highlightText)}
      </p>
      {item.description ? (
        <p className="mt-2 text-center text-xs leading-tight">
          {highlightMatch(item.description, highlightText)}
        </p>
      ) : null}
    </div>
  );

  return (
    <>
      {title && <h2 className="my-2 font-semibold">{highlightMatch(title, highlightText)}</h2>}
      <div className="grid w-full grid-cols-[repeat(auto-fit,_minmax(100px,_1fr))] gap-px md:grid-cols-[repeat(auto-fit,_minmax(160px,_1fr))]">
        {items.map((item) => renderItem(item))}
      </div>
    </>
  );
}

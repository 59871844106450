import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Creator, GenderTypes, useUpdateCreatorMutation } from "@/generated-types";
import { GENDER_VALUES } from "@/utils/options";

type GenderMiniFormProps = {
  creator: Pick<Creator, "id" | "gender" | "genderOther"> | undefined;
  onClose?: () => void;
};

export function GenderMiniForm({ creator, onClose }: GenderMiniFormProps) {
  const [updateCreator, { loading: updatingCreator }] = useUpdateCreatorMutation({
    refetchQueries: ["GetCreatorByAccount"],
  });

  const FormSchema = z.object({
    gender: z
      .enum([GenderTypes.Male, GenderTypes.Female, GenderTypes.Nonbinary, GenderTypes.Other])
      .optional(),
    genderOther: z.string().optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      gender: creator?.gender || undefined,
      genderOther: creator?.genderOther || "",
    },
    mode: "onBlur",
  });

  const {
    formState: { isValid },
    setValue,
    watch,
  } = form;

  const genderValue = watch("gender");

  // if the user choose any gender other than "other", clear the genderOther field
  useEffect(() => {
    if (genderValue && genderValue !== GenderTypes.Other)
      setValue("genderOther", "", { shouldDirty: true });
  }, [genderValue, setValue]);

  async function handleSubmit(data: z.infer<typeof FormSchema>) {
    await updateCreator({
      variables: {
        id: creator?.id!,
        data,
      },
    });
    onClose && onClose();
  }

  return (
    <Form {...form}>
      {/* our fields */}
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-2">
        {/* gender */}
        <FormField
          control={form.control}
          name="gender"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select your gender" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(GENDER_VALUES).map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* gender other */}
        <FormField
          control={form.control}
          name="genderOther"
          render={({ field }) => (
            <FormItem hidden={genderValue !== GenderTypes.Other}>
              <FormControl>
                <Input {...field} placeholder="Please enter your gender" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={updatingCreator}
          onBack={onClose}
          nextLabel="Save"
          backLabel="Cancel"
        />
      </form>
    </Form>
  );
}

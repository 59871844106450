import { useAuth0 } from "@auth0/auth0-react";
import first from "lodash/first";
import React from "react";

import { USER_TYPE_VALUES } from "@/utils/options";
import { RefetchOnFocus } from "@/utils/query";

import {
  useGetContentCountForCreatorQuery,
  useGetContentCountForOwnerQuery,
  useGetCreatorByAccountQuery,
  useGetOwnerByAccountQuery,
  useGetProfileQuery,
  UserType,
} from "@/generated-types";
import { formatLegalName } from "@/utils/formatter";
import { ContentLibrary } from "../content-page/content-library";
import { GettingStartedOverview } from "../public-home/overview";
import { ActivityFeed } from "./activity";
import DashboardHead from "./header";

export default function DashboardPage() {
  const { user } = useAuth0();
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();

  const userType = profileData?.me?.userType;
  const userCreatorId = first(profileData?.me?.account?.creators)?.id;
  const userOwnerId = profileData?.me?.account?.owner?.id;

  const { data: creatorData, loading: loadingCreator } = useGetCreatorByAccountQuery({
    variables: { accountId: profileData?.me?.account?.id || "" },
    skip: userType !== UserType.Creator,
  });

  const { data: ownerData, loading: loadingOwner } = useGetOwnerByAccountQuery({
    variables: { accountId: profileData?.me?.account?.id || "" },
    skip: !profileData || userType !== UserType.Owner,
  });

  const firstCreator = first(creatorData?.creators);
  const firstOwner = first(ownerData?.owners);
  // technically there is a maximum of one owner, however, the graphql schema is defined to return an array, even there is only one record

  const {
    data: countForCreator,
    loading: loadingCountForCreator,
    refetch: refetchCountForCreator,
  } = useGetContentCountForCreatorQuery({
    variables: { creatorId: userCreatorId! },
    skip: !userCreatorId,
  });

  const {
    data: countForOwner,
    loading: loadingCountForOwner,
    refetch: refetchCountForOwner,
  } = useGetContentCountForOwnerQuery({
    variables: { ownerId: userOwnerId! },
    skip: !userOwnerId,
  });

  const typedUser = {
    email: user?.email || "",
    name: userType === UserType.Creator ? formatLegalName(firstCreator) : firstOwner?.name,
    email_verified: user?.email_verified || false,
    nickname: user?.nickname || "",
    picture: user?.picture || "",
    sub: user?.sub || "",
    userType: USER_TYPE_VALUES[userType as UserType],
    updated_at: user?.updated_at || "",
  };

  // TODO: fix this to show count for creators as well as owners
  const channelCount =
    (first(countForCreator?.creators)?.channelsAggregate?.count || 0) +
    (first(countForOwner?.owners)?.channelsAggregate?.count || 0);

  const workCount =
    (first(countForCreator?.creators)?.worksAggregate?.count || 0) +
    (first(countForOwner?.owners)?.worksAggregate?.count || 0);

  return (
    <>
      <main className="container mx-auto space-y-16">
        <GettingStartedOverview />

        {/* divider */}
        {/* <div className="mb-2 mt-8 border-t-2 border-dashed border-gray-900/10 dark:border-gray-400" /> */}

        <DashboardHead
          user={typedUser}
          channelCount={channelCount}
          workCount={workCount}
          className="container shadow-md"
          loading={
            loadingProfile ||
            loadingCreator ||
            loadingOwner ||
            loadingCountForCreator ||
            loadingCountForOwner
          }
        />

        <ActivityFeed
          activities={["created", "payment", "identity", "profile"]}
          className="container"
        />
        <ContentLibrary profile={profileData?.me} />
        <RefetchOnFocus refetchers={[refetchCountForCreator, refetchCountForOwner]} />
      </main>
    </>
  );
}

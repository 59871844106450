import ellipsize from "ellipsize";
import { FileTextIcon } from "lucide-react";
import { useRef } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import {
  useDeleteUserMediaMutation,
  UserMedia,
  useUpdateChannelMutation,
  useUpdateUserMediaMutation,
  useUpdateWorkMutation,
} from "@/generated-types";
import { QuickConfirm, QuickConfirmRef } from "@/utils/confirm";
import { makeMediaURL } from "@/utils/formatter";
import { MediaForm } from "./content-media-form";
import { BasicDropzone } from "./my-react-dropzone";

const MAX_MEDIA_SIZE = 5 * 1024 * 1024;
const MAX_MEDIA_SIZE_TEXT = "5MB";
const MAX_MEDIA_COUNT = 10;

type MediaItem = Pick<UserMedia, "id" | "extension" | "sourceType" | "caption">;
type ContentItem = { id: string; thumbnail?: { id: string } | null };

type MediaGridProps = {
  typeOfContent: "channel" | "work";
  content: ContentItem | undefined;
  media: MediaItem[] | undefined;
};

export function MediaGrid({ typeOfContent, content, media = [] }: MediaGridProps) {
  const [updateChannel, { loading: updatingChannel }] = useUpdateChannelMutation();
  const [updateWork, { loading: updatingWork }] = useUpdateWorkMutation();
  const [updateMedia, { loading: updatingMedia }] = useUpdateUserMediaMutation();
  const [deleteMedia, { loading: deletingMedia }] = useDeleteUserMediaMutation({
    refetchQueries: ["GetChannelById", "GetWorkById"],
  });

  const confirmRef = useRef<QuickConfirmRef>(null);

  function handleSuccessUpload(media: MediaItem[]) {
    const mutationFn = typeOfContent === "channel" ? updateChannel : updateWork;
    mutationFn({
      variables: {
        where: { id: content?.id },
        update: {
          media: [
            {
              connect: media.map((item) => ({ where: { node: { id: item.id } } })),
            },
          ],
        },
      },
    });
  }

  async function handleSetThumbnail(newMediaId: string | undefined) {
    const connect = newMediaId
      ? {
          where: { node: { id: newMediaId } },
        }
      : null;
    const disconnect =
      content?.thumbnail?.id && content?.thumbnail?.id !== newMediaId
        ? {
            where: { node: { id: content.thumbnail.id } },
          }
        : null;

    const mutationFn = typeOfContent === "channel" ? updateChannel : updateWork;
    if (connect || disconnect)
      await mutationFn({
        variables: {
          where: { id: content?.id },
          update: {
            thumbnail: {
              disconnect,
              connect,
            },
          },
        },
      });
  }

  async function handleDeleteItem(userMediaId: string) {
    const answer = await confirmRef.current?.confirm(
      `Deleting this media item?`,
      `This operation can't be undone. Please confirm.`
    );
    if (answer) {
      await deleteMedia({ variables: { ids: [userMediaId] } });
    }
  }

  async function handleUpdateItem(item: Pick<UserMedia, "id" | "caption">) {
    await updateMedia({ variables: { id: item.id, update: { caption: item.caption } } });
  }

  function handleFailedUpload() {
    confirmRef.current?.alert("Error", "File not supported.");
  }

  return (
    <PhotoProvider>
      <div className="mb-4 flex flex-wrap gap-4">
        {media.map((mediaItem) => {
          const url = makeMediaURL(mediaItem);
          return (
            <div key={mediaItem.id} className="relative w-64 bg-white p-4">
              {mediaItem.extension === "txt" ? (
                <div className="flex min-h-32 w-full items-center justify-center">
                  <a href={url} rel="noreferrer" target="_blank">
                    <FileTextIcon size={48} />
                  </a>
                </div>
              ) : (
                <PhotoView src={url}>
                  <img
                    src={url}
                    alt={mediaItem.caption || ""}
                    className="w-full cursor-pointer object-scale-down"
                  />
                </PhotoView>
              )}
              {mediaItem.caption && (
                <p className="mt-1 text-xs">{ellipsize(mediaItem.caption, 80)}</p>
              )}
              <MediaForm
                typeOfContent={typeOfContent}
                item={mediaItem}
                asThumbnail={content?.thumbnail?.id === mediaItem.id}
                onDelete={handleDeleteItem}
                onSetThumbnail={handleSetThumbnail}
                onUpdate={handleUpdateItem}
              />
            </div>
          );
        })}
        {media.length < MAX_MEDIA_COUNT && (
          <BasicDropzone
            maxSize={MAX_MEDIA_SIZE}
            maxSizeText={MAX_MEDIA_SIZE_TEXT}
            onSuccess={handleSuccessUpload}
            onFailure={handleFailedUpload}
          />
        )}
      </div>
      <QuickConfirm ref={confirmRef} />
    </PhotoProvider>
  );
}

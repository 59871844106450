import React from "react";
import { useNavigate } from "react-router-dom";

import { PageLoader } from "@/components/page-loader";
import { useGetProfileQuery, UserType } from "@/generated-types";

export default function ChooseProfilePage() {
  const navigate = useNavigate();
  const {
    data: profileData,
    loading: profileLoading,
    refetch: refectchProfile,
  } = useGetProfileQuery({
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (profileData?.me) {
      if (profileData?.me?.userType === UserType.Creator) navigate("./creator");
      else navigate("./rights-owner");
    }
  }, [profileData, navigate]);

  return <PageLoader />;
}

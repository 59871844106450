import { Outlet } from "react-router-dom";
import { ReactNode } from "react";
import { NavBar } from "@/components/navigation";
import CookieConsent from "react-cookie-consent";

export default function PublicLayout() {
  return (
    <div className="h-screen overflow-auto">
      <NavBar />
      <div id="content" className="container mx-auto mt-16 h-[calc(100vh_-_4rem)] py-4">
        <CookieConsent
          buttonText="Got it!"
          cookieName={"CredtentAppCookies"}
          buttonStyle={{ backgroundColor: "#290099", borderRadius: "4px", color: "white" }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Outlet />
        <div className="-mb-4 -mr-4 mt-4 text-right text-xs">
          &copy; Credtent, Inc. {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
}
/**
 * Hash link offset
 * Solves the problem of content gets scrolled to the top of the screen and coverved by the nav bar
 * Remember to change the negative margin and padding below to match the NavBar height, whenever it changes
 *
 * Usage: just wrap the heading element with it, e.g.
 * <HashLinkTarget id="services">
 *   <h2>Services</h2>
 * </HashLinkTarget>
 */
type HashLinkTargetPros = {
  id: string;
  children: ReactNode;
};

export function HashLinkTarget({ id, children }: HashLinkTargetPros) {
  return (
    <div id={id} className="-mt-16 pt-16">
      {children}
    </div>
  );
}

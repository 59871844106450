import { FC } from "react";
import { Link } from "react-router-dom";
import { FolderPlusIcon } from "lucide-react";

import { Channel, MediaSourceType, UserMedia, Work } from "@/generated-types";
import { CategoryIconWithLabel } from "./category-icon";
import { Button } from "../ui/button";
import { makeMediaURL } from "@/utils/formatter";

type MediaItem = Pick<UserMedia, "id" | "extension" | "sourceType">;

type ContentListItemProps = {
  channel?: Pick<Channel, "id" | "channelName" | "description" | "channelUrl"> & {
    thumbnail?: Pick<UserMedia, "id" | "extension" | "sourceType"> | null;
  };
  work?: Pick<Work, "id" | "title" | "description" | "workUrl"> & {
    thumbnail?: Pick<UserMedia, "id" | "extension" | "sourceType"> | null;
  };
  types: { title?: string | null | undefined }[];
  categories: {
    id: string;
    title?: string | null | undefined;
    icon?: string | null | undefined;
  }[];
  media: MediaItem[] | undefined;
};

function chooseThumbnail(
  media: MediaItem[] = [],
  thumbnail: MediaItem | undefined | null
): MediaItem | undefined {
  if (thumbnail) return thumbnail;
  let selected: MediaItem | undefined = undefined;
  for (const item of media) {
    if (item.extension !== "txt") selected = item;
    if (item.sourceType === MediaSourceType.OpenGraph) break;
  }
  return selected;
}

const ContentListItem: FC<ContentListItemProps> = ({ channel, work, types, categories, media }) => {
  const itemId = channel ? "c$" + channel.id : work ? "w$" + work.id : "";
  const typesText = types?.map((type) => type.title).join(", ");
  const categoriesText = categories?.map((category) => category.title).join(" | ");
  const title = channel
    ? channel.channelName || channel.description
    : work
      ? work.title || work.description
      : "(no title)";
  const subtitle = channel
    ? channel.channelName
      ? channel.description
      : undefined
    : work
      ? work.title
        ? work.description
        : undefined
      : undefined;
  const url = channel?.channelUrl || work?.workUrl;

  const thumbnail = chooseThumbnail(media, channel?.thumbnail || work?.thumbnail);

  return (
    <li key={itemId}>
      <div className="w-full lg:flex">
        {/* don't combine rounded-tl and rounded-tr, it'll cause problem */}
        {/* eslint-disable-next-line */}
        <div className="flex h-48 flex-col items-center overflow-hidden rounded-tl rounded-tr border bg-white lg:h-auto lg:w-48 lg:min-w-48 lg:rounded-bl lg:rounded-tr-none">
          <div className="grow">
            {thumbnail ? (
              <img
                className="mx-6 mt-6 aspect-square size-36 object-scale-down"
                src={makeMediaURL(thumbnail)}
                alt=""
              />
            ) : (
              <CategoryIconWithLabel cat={categories?.[0]} size="lg" className="size-48 pt-5" />
            )}
          </div>
          <Link to={`/content/add-similar/${itemId}`}>
            <Button variant="ghost" size="sm" className="mb-2 flex gap-2 text-sm">
              <FolderPlusIcon className="size-4" aria-hidden="true" /> Add Similar
            </Button>
          </Link>
        </div>
        <div className="flex w-full flex-col rounded-b border-x border-b bg-white p-4 leading-normal lg:rounded-b-none lg:rounded-r lg:border-l-0 lg:border-t">
          {/* name */}
          <div className="mb-0 text-xl font-bold text-black">
            <Link to={`/content/${itemId}/basic-content-info`}>{title}</Link>
          </div>

          {/* description, if any */}
          {subtitle ? <p className="text-base">{subtitle}</p> : null}

          {/* category icons */}
          <div className="mt-4 flex flex-row flex-wrap gap-x-0.5 gap-y-2">
            {categories
              ?.slice(thumbnail ? 0 : 1) // if there is user media (such as a screenshot), we'll display all the category icons here, otherwise we'll use the first category icon in place of the user media
              .map((cat) => <CategoryIconWithLabel key={cat.id} cat={cat} className="w-20" />)}
          </div>

          {/* content types */}
          <p className="mt-4 text-sm">Content Type: {typesText}</p>

          {/* categories in text */}
          <div className="mt-2 align-middle text-sm">
            {/* lock icon */}
            <div className="inline-block">
              <svg className="mr-2 size-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
              </svg>
            </div>
            {categoriesText}
          </div>
          {/* channel address */}
          {url && (
            <div className="mt-2 text-sm">
              <p>
                Address:{" "}
                <a target="_blank" href={url || "#"} rel="noreferrer">
                  {url}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ContentListItem;

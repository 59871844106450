/**
 * some useful hooks for accessing TypeTags (and maybe others)
 */

import { useNewCategoryTagsQuery, useTypeTagsQuery } from "@/generated-types";
import difference from "lodash/difference";
import first from "lodash/first";
import keyBy from "lodash/keyBy";
import some from "lodash/some";
import sortBy from "lodash/sortBy";

import { useMemo, useState } from "react";

interface Dictionary<T> {
  [index: string]: T;
}

type CleanTypeTag = {
  id: string;
  title: string;
  tier: number;
  parentId: string | undefined;
  hasChildren: boolean;
};

export const useTypeTags = () => {
  const { data } = useTypeTagsQuery();

  return useMemo(() => {
    // turn `tags?.count` to a boolean
    // turn parentTag.id to a parentId
    const cleanedData: CleanTypeTag[] =
      data?.typeTags.map((item) => ({
        id: item.id || "",
        title: item.title || "",
        tier: item.tier || 0,
        parentId: first(item.parentTag)?.id,
        hasChildren: (item.tagsAggregate?.count || 0) > 0,
      })) || [];

    const map = keyBy(cleanedData, "id");

    return {
      typeTags: cleanedData,
      typeTagsMap: map,
    };
  }, [data]);
};

export type SelectCategoryTag = {
  id: string;
  title: string;
  tier: number;
  icon: string | undefined;
  description: string | undefined;
  parent?: SelectCategoryTag | undefined;
  children?: SelectCategoryTag[] | undefined;
  selected: boolean;
};

export const useCategoryTags = () => {
  const { data } = useNewCategoryTagsQuery();
  const [map, setMap] = useState<Dictionary<SelectCategoryTag>>({});
  const [roots, setRoots] = useState<SelectCategoryTag[]>([]);

  function toggleSelect(tagId: string, selected: boolean) {
    // first, select/deselect self
    map[tagId].selected = selected;

    // then update its parent tag to reflect the selection change
    const parentTag = map[tagId].parent;
    if (parentTag) {
      // when a tag is selected, it's parent is selected automatically
      if (selected) parentTag.selected = true;
      // when a tag is deselected, it's parent gets deselected only when all its children are deselected
      else parentTag.selected = some(parentTag.children || [], { selected: true });
    }

    // trigger a rerender so the display gets updated
    setMap({ ...map });
  }

  function initialize(value: { id: string }[]) {
    const initialMap: Dictionary<SelectCategoryTag> = {};
    const tier0: SelectCategoryTag[] = [];

    for (const item of data?.categoryTags || []) {
      // first check if a tag with this item's id already exists in the map, this happens when a child tag is processed before it's parent
      const matchingTag: SelectCategoryTag = initialMap[item.id] || {};
      matchingTag.id = item.id;
      matchingTag.title = item.title || "";
      matchingTag.icon = item.icon || undefined;
      matchingTag.description = item.description || undefined;
      matchingTag.tier = item.tier || 0;
      matchingTag.selected = false;

      initialMap[item.id] = matchingTag;

      const parentId = first(item.parentTag)?.id;
      if (parentId) {
        // trying to hook up with the parent of this tag
        if (!initialMap[parentId]) {
          // ouch, parent tag hasn't been processed yet
          initialMap[parentId] = {
            id: parentId,
            title: "placeholder",
            tier: 0,
            icon: "",
            description: undefined,
            selected: false,
          };
        }

        matchingTag.parent = initialMap[parentId];
        // add this tag to the children array of its parent
        initialMap[parentId].children = [...(initialMap[parentId].children || []), matchingTag];
      } else {
        tier0.push(matchingTag);
      }
    }

    // select the tags passed in
    value.forEach((item) => {
      initialMap[item.id].selected = true;
      const parent = initialMap[item.id].parent;
      if (parent) parent.selected = true;
    });

    setMap(initialMap);
    setRoots(sortBy(tier0, "title"));
  }

  function browseTags(rootTagId: string | undefined) {
    return rootTagId ? map[rootTagId].children || [] : roots;
  }

  function searchTags(searchTerm: string) {
    const termLC = searchTerm.trim().toLowerCase();
    const filteredItems: { title: string; items: SelectCategoryTag[] }[] = [];
    for (const root of roots) {
      if (root.title.toLowerCase().includes(termLC)) {
        // root category matches the search term
        if (root.children)
          // and the root category has children, put all the children in the search result
          filteredItems.push({ title: root.title, items: root.children });
        // the root category has no child, just put the root category itself in
        else filteredItems.push({ title: root.title, items: [root] });
      } else {
        // root doesn't match, check if any of its children match
        const filteredSubItems = root.children?.filter((item) =>
          item.title.toLowerCase().includes(termLC)
        );
        if (filteredSubItems && filteredSubItems.length > 0)
          filteredItems.push({ title: root.title, items: filteredSubItems });
      }
    }
    return filteredItems;
  }

  function getValue() {
    return Object.values(map).filter((item) => item.selected && !item.children);
  }

  return {
    initialize,
    browseTags,
    searchTags,
    toggleSelect,
    getValue,
  };
};

export function resolveConnection(
  newItems: string[] | { id: string | undefined }[],
  oldItems: string[] | { id: string | undefined }[] = []
) {
  const oldIds: string[] = oldItems
    .map((item) => (typeof item === "string" ? item : item.id))
    .filter((idOrUnd) => typeof idOrUnd !== "undefined");

  const newIds: string[] = newItems
    .map((item) => (typeof item === "string" ? item : item.id))
    .filter((idOrUnd) => typeof idOrUnd !== "undefined");

  const connectIds = difference(newIds, oldIds);
  const disconnectIds = difference(oldIds, newIds);

  const disconnetWhere =
    disconnectIds.length === 0
      ? undefined
      : {
          disconnect: disconnectIds.map((id) => ({
            where: { node: { id } },
          })),
        };

  const connectWhere =
    connectIds.length === 0
      ? undefined
      : {
          connect: connectIds.map((id) => ({
            where: { node: { id } },
          })),
        };

  return connectWhere || disconnetWhere
    ? {
        ...connectWhere,
        ...disconnetWhere,
      }
    : undefined;
}

export function resolveConnectForUpdate(
  newItems: string[] | { id: string | undefined }[],
  oldItems: string[] | { id: string | undefined }[]
) {
  const resolved = resolveConnection(newItems);
  if (resolved) return [resolved];
  else return undefined;
}

// @ts-ignore (the following package doesn't have @types definition)
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import first from "lodash/first";
import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  useUpdateChannelMutation,
  useUpdateCreatorMutation,
  useUpdateOwnerMutation,
  useUpdateWorkMutation,
} from "generated-types";

import { cn } from "@/utils";

type TagsEditorProps = {
  value: string[] | undefined;
  placeholder?: string;
  className?: string;
  pattern?: RegExp;
  setLocalValue?: (value: string | undefined) => void;
  onChange?: (value: string[]) => void;
  onSave?: (value: string[] | undefined) => void;
  doSave?: boolean; // used by the container to trigger a save event
};

export const TagsEditor: FC<TagsEditorProps> = ({
  value,
  placeholder,
  className,
  pattern,
  setLocalValue,
  onChange,
  onSave,
  doSave,
}) => {
  const onTagsChange = (e: any) => {
    // console.log("tags changed", e);
    console.log(
      "CHANGED:",
      e.detail.tagify.value, // Array where each tag includes tagify's (needed) extra properties
      e.detail.tagify.getCleanValue(), // Same as above, without the extra properties
      e.detail.value // a string representing the tags
    );

    const tagArray = e.detail.tagify.value.map((item: { value: string }) => item.value);
    setLocalValue && setLocalValue(tagArray.join(", "));
    onChange && onChange(tagArray);
  };

  const tagifySettings = {
    pattern: pattern || /^[a-zA-Z0-9_]{1,30}$/,
    // the default pattern is only alphanumeric characters and underscores, up to 30 characters
  };

  return (
    <Tags
      settings={tagifySettings}
      placeholder={placeholder}
      defaultValue=""
      value={value || []}
      onChange={onTagsChange}
      className={cn(
        "min-h-20 w-full rounded-md border border-input text-sm text-slate-700",
        className
      )}
    />
  );
};

export function useTagEditor() {
  const [loading, setLoading] = useState(false);
  const [updateChannel] = useUpdateChannelMutation();
  const [updateWork] = useUpdateWorkMutation();
  const [updateCreator] = useUpdateCreatorMutation();
  const [updateOwner] = useUpdateOwnerMutation();

  // turn lists of "before" and "after" into disconnect and connectOrCreate update inputs
  const resolveTags = (
    afterTitles: string[],
    beforeTags: { id: string; title?: string | null; blocked?: boolean | null }[] = []
  ) => {
    // these are the IDs of tags that the user no longer wants
    const disconnectIds: string[] = beforeTags
      ?.filter((tag) => tag.title && !afterTitles.includes(tag.title))
      .map((tag) => tag.id);

    // all the titles of existing tags, note creator.tags is an array of object of { id, title }
    const beforeTitles = beforeTags.map((tag) => tag.title);

    // these are the titles the user wants to add, some of them might already exists, so we need to find out which ones exist, and their IDs
    const connectOrCreateTitles: string[] = afterTitles.filter(
      (title) => !beforeTitles.includes(title)
    );

    return {
      disconnect:
        disconnectIds.length > 0
          ? disconnectIds.map((id) => ({ where: { node: { id } } }))
          : undefined,
      connectOrCreate:
        connectOrCreateTitles.length > 0
          ? connectOrCreateTitles.map((title) => ({
              where: { node: { title } },
              onCreate: { node: { id: uuidv4(), title } },
            }))
          : undefined,
    };
  };

  const handleUpdateTags = async (
    entity: "creator" | "owner" | "channel" | "work",
    id: string,
    afterTitles: string[],
    beforeTags: { id: string; title?: string | null; blocked?: boolean | null }[] = []
  ) => {
    const tagsUpdate = resolveTags(afterTitles, beforeTags);
    if (!tagsUpdate.disconnect && !tagsUpdate.connectOrCreate) return;

    setLoading(true);
    let resultTags: { id: string; title?: string | null; blocked?: boolean | null }[] = [];

    switch (entity) {
      case "channel":
        await updateChannel({
          variables: {
            where: { id },
            update: {
              tags: [tagsUpdate],
            },
          },
        });
        break;
      case "work":
        await updateWork({
          variables: {
            where: { id },
            update: {
              tags: [tagsUpdate],
            },
          },
        });
        break;
      case "creator":
        const updateCreatorResult = await updateCreator({
          variables: {
            id,
            data: {
              tags: [tagsUpdate],
            },
          },
        });
        resultTags = first(updateCreatorResult.data?.updateCreators.creators)?.tags || [];
        break;
      case "owner":
        const updateOwnerResult = await updateOwner({
          variables: {
            id,
            data: {
              tags: [tagsUpdate],
            },
          },
        });
        resultTags = first(updateOwnerResult.data?.updateOwners.owners)?.tags || [];
        break;
    }
    setLoading(false);
    return resultTags;
  };

  return {
    resolveTags,
    handleUpdateTags,
    loading,
  };
}
